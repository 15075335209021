<template>
  <div v-if="ConfApp.controls.btn_theme" class="display-0-1 theme-switcher">
    <v-switch
      v-model="switchDarkTheme"
      @change="flipColor"
      @click="wasSwitchedSwitcher()"
      :input-value="switchDarkTheme"
      :class="{ 'pulse-switcher': !User.was_switched }"
      color="primary"
      inset
    >
      <template v-slot:label>
        <v-icon v-if="DarkTheme.enable" color="primary"
          >mdi-weather-sunny</v-icon
        >
        <v-icon v-else>mdi-weather-night</v-icon>
        {{ color }}
      </template>
    </v-switch>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BtnThemeSwitcher",
  data() {
    return {
      switchDarkTheme: false
    };
  },
  computed: {
    ...mapState({
      ConfApp: (state) => state.conf.ConfApp,
      DarkTheme: (state) => state.conf.DarkTheme,
      User: (state) => state.account.User
    }),
    color() {
      if (this.DarkTheme.enable) {
        return "светлая";
      } else {
        return "темная";
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.switchDarkTheme = this.DarkTheme.enable;
    }, 0);
  },
  methods: {
    flipColor() {
      if (this.DarkTheme.enable) {
        this.$vuetify.theme.dark = false;
        this.switchDarkTheme = false;
        this.$store.commit("conf/update_dark_theme", false);
        this.$store.commit("conf/update_conf_app_theme", "light");
        localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
        document.getElementsByTagName("html")[0].dataset.theme = "light";
      } else {
        this.$vuetify.theme.dark = true;
        this.switchDarkTheme = true;
        this.$store.commit("conf/update_dark_theme", true);
        this.$store.commit("conf/update_conf_app_theme", "dark");
        localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
        document.getElementsByTagName("html")[0].dataset.theme = "dark";
      }
    },
    wasSwitchedSwitcher() {
      localStorage.setItem("was_switched", JSON.stringify(true));
      this.$store.commit("account/update_was_switched", true);
    }
  }
};
</script>
