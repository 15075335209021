import Store from "@/store/index";
import Router from "@/router";

export const commonReportingMethods = {
  methods: {
    // Defined Centres | Defined Gates | Defined Activate Gates Design, Personal, Both
    isDefinedObj: (items, n) => {
      let value = false;
      for (const key in items) {
        // console.log("inside isDefinedObj: ", items[key] === n, items, n);
        if (items[key] === n) value = true;
      }
      return value;
    },
    // Method for lighting & effects gates
    isDefinedArr: (arr, n) => {
      if (arr) {
        for (const element of arr) {
          if (element === n) return true;
        }
      }
    },
    matchChannels: () => {
      if (
        Object.keys(Store.state.account.User.chartBodygraph.channels2)
          .length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.account.User.chartBodygraph.channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.account.User.chartBodygraph.channels2
        ).map((key) => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]: Store.state.account.User.chartBodygraph.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.account.User.chartBodygraph.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.account.User.chartBodygraph.channels2);
      }
    },
    matchCompositeChannels: () => {
      if (
        Object.keys(Store.state.account.User.chartComposite.chart.channels2)
          .length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.account.User.chartComposite.chart
          .channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.account.User.chartComposite.chart.channels2
        ).map((key) => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]:
              Store.state.account.User.chartComposite.chart.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.account.User.chartComposite.chart.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.account.User.chartComposite.chart.channels2);
      }
      if (
        Object.keys(Store.state.account.User.chartComposite.chart2.channels2)
          .length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.account.User.chartComposite.chart2
          .channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.account.User.chartComposite.chart2.channels2
        ).map((key) => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]:
              Store.state.account.User.chartComposite.chart2.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.account.User.chartComposite.chart2.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.account.User.chartComposite.chart2.channels2);
      }
    },
    matchInflictionChannels: () => {
      if (
        Object.keys(
          Store.state.account.User.chartTransitBodygraph.chart.channels2
        ).length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.account.User.chartTransitBodygraph.chart
          .channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.account.User.chartTransitBodygraph.chart.channels2
        ).map((key) => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]:
              Store.state.account.User.chartTransitBodygraph.chart.channels2[
                key
              ]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.account.User.chartTransitBodygraph.chart.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.account.User.chartTransitBodygraph.chart.channels2);
      }
    },
    // New Report
    newReport: function() {
      localStorage.setItem("name", "");
      localStorage.setItem("day", JSON.stringify({ day: "15" }));
      localStorage.setItem(
        "month",
        JSON.stringify({ month: "Июнь (6)", id: "06" })
      );
      localStorage.setItem("year", JSON.stringify({ year: "1990" }));
      localStorage.setItem("hour", JSON.stringify({ hour: "12" }));
      localStorage.setItem("minute", JSON.stringify({ minute: "30" }));
      localStorage.setItem("lastCities", JSON.stringify([]));
      localStorage.setItem("cityId", "");
      localStorage.setItem("chartBodygraph", "");
      localStorage.setItem("toolTips", "");
      localStorage.setItem("reportId", "");
      localStorage.setItem("user_products_id", "");
      localStorage.setItem("is_stored", 0);
      localStorage.setItem("isProTooltips", false);
      Store.commit("account/update_name", "");
      // Store.commit("account/update_first_name", "");
      Store.commit("account/update_year", { year: "1990" });
      Store.commit("account/update_month", { month: "Июнь (6)", id: "06" });
      Store.commit("account/update_day", { day: "15" });
      Store.commit("account/update_hour", { hour: "12" });
      Store.commit("account/update_minute", { minute: "30" });
      Store.commit("account/update_city_id", null);
      Store.commit("account/update_last_cities", []);
      Store.commit("account/update_chart_bodygraph", null);
      Store.commit("account/update_tooltips", {});
      Store.commit("account/update_report_id", "");
      Store.commit("account/update_user_product_id", "");
      Store.commit("account/update_is_stored", 0);
      if (Router.history.current.path !== "/") this.goToPath("/");
      Store.commit("account/is_pro_tooltips", false);
      // blinkFirstime
      if (localStorage.getItem("blinkFirstime")) {
        localStorage.setItem("blinkFirstime", true);
        Store.commit("conf/update_blink_first_time", false);
      }
      localStorage.removeItem("tooltip_button_type");
      Store.commit("conf/update_tooltips_button_type", 0);
      // Remove User Product ID/Hash
      Store.dispatch("account/removeUserProduct");
    },
    // Formatting time stamp
    formatCommDate: (ts) => {
      const locale = "ru-Ru";
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Moscow"
      };
      if (ts) {
        return new Intl.DateTimeFormat(locale, options).format(+ts * 1000);
      } else {
        return "";
      }
    },
    newComposite: () => {
      Store.commit("account/set_chart_bodygraph_composite", null);
      Store.commit("account/set_chart_bodygraph_composite2", null);
      Store.commit("account/update_composite_report_id", null);
      Store.commit("account/update_composite_report_hash", null);
      if (Router.history.current.path !== "/composite")
        Router.push("/composite");
    },
    goTooltips: (prefix, val) => {
      if (Store.state.account.openTooltips[prefix + val]) {
        Store.commit("account/clear_open_tooltips", {});
      } else {
        Store.commit("account/clear_open_tooltips", {});
        Store.state.account.openTooltips[prefix + val] = true;
      }
    },
    showTooltips: (prefix, val) => {
      if (Store.state.account.openTooltips[prefix + val]) {
        return true;
      } else {
        return false;
      }
    },
    getTooltipsMsg: (obj, reportType) => {
      let mkey1, mkey2, mkey3, mkey4, mkey5, mkey6, mkey7, buttonType;
      switch (reportType) {
        case 1:
          mkey1 = "tooltip_normal";
          mkey2 = "tooltip_notconfirmed";
          mkey3 = "tooltip_full";
          mkey4 = "tooltip_pro";
          mkey6 = "tooltip_buy_full";
          mkey7 = "tooltip_switch_on_full";
          buttonType = Store.state.conf.ConfApp.tooltip_button_type;
          break;
        case 2:
          mkey1 = "tooltip_composite_normal";
          mkey2 = "tooltip_composite_notconfirmed";
          mkey3 = "tooltip_composite_full";
          // mkey4 = "tooltip_composite_pro";
          mkey4 = "tooltip_buy_full";
          mkey5 = "tooltip_composite_buy";
          mkey6 = "tooltip_buy_full";
          mkey7 = "tooltip_switch_on_full";
          buttonType = Store.state.conf.ConfApp.cp_tooltip_button_type;
          break;
        case 3:
          mkey1 = "tooltip_normal";
          mkey2 = "tooltip_notconfirmed";
          mkey3 = "tooltip_full";
          mkey4 = "tooltip_pro";
          mkey6 = "tooltip_buy_full";
          mkey7 = "tooltip_switch_on_full";
          buttonType = Store.state.conf.ConfApp.tr_tooltip_button_type;
          break;
        case 4:
          mkey1 = "tooltip_normal";
          mkey2 = "tooltip_notconfirmed";
          mkey3 = "tooltip_full";
          mkey4 = "tooltip_pro";
          mkey6 = "tooltip_buy_full";
          mkey7 = "tooltip_switch_on_full";
          buttonType = Store.state.conf.ConfApp.rt_tooltip_button_type;
          break;
      }
      // console.log("buttonType is: ", buttonType);
      switch (buttonType) {
        case 1: {
          const gexColor = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon +
            obj.title +
            "</h3>" +
            obj.tooltip +
            "<div class='tooltip-inner-divider'></div>";
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey1]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey1
          );
          return msg;
        }
        case 2: {
          Store.commit("UPDATE_IS_VISIBLE_TOOLTIP_BTN", false);
          const gexColor2 = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon2 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor2 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg2 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon2 +
            obj.title +
            "</h3>" +
            obj.tooltip +
            '<div class="tooltip-inner-last-child"></div>';
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey2]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey2
          );
          return msg2;
        }
        case 3: {
          const gexColor3 = Store.state.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon3 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor3 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg3 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon3 +
            obj.title +
            "</h3>" +
            obj.tooltip +
            '<div class="tooltip-inner-divider"></div>';
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey3]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey3
          );
          return msg3;
        }
        case 4: {
          const gexColor4 = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon4 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor4 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg4 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon4 +
            obj.title +
            "</h3>" +
            obj.tooltip +
            '<div class="tooltip-inner-divider"></div>';
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey4]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey4
          );
          return msg4;
        }
        case 5: {
          const gexColor5 = Store.state.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon5 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor5 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg5 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltipsCP()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon5 +
            obj.title +
            "</h3>" +
            obj.tooltip +
            "<div class='tooltip-inner-divider'></div>";
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey5]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey5
          );
          return msg5;
        }
        case 6: {
          const gexColor6 = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon6 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor6 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg6 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon6 +
            obj.title +
            "</h3>" +
            // TOOLTIPS_FULL + // Заккоментил пока верхнее предложение подсказок (tooltip-inner-btn-offer)
            obj.tooltip +
            '<div class="tooltip-inner-divider"></div>';
          // Когда приходит 6, вверху tooltip-inner-divider временно не показываем, а внизу FULL-подсказки - 6
          Store.commit(
            "Uaccount/update_reg_go_text_before_btn_" + reportType,
            Store.state.account.Messages[mkey6]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey6
          );
          return msg6;
        }
        case 7: {
          const gexColor7 = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const gexIcon7 = obj.icon
            ? '<span class="tooltips-title-hexagram" style="color:' +
              gexColor7 +
              '">' +
              obj.icon +
              "</span> "
            : "";
          const msg7 =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top">' +
            gexIcon7 +
            obj.title +
            "</h3>" +
            obj.tooltip +
            '<div class="tooltip-inner-divider"></div>';
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages[mkey7]
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_" + mkey7
          );
          return msg7;
        }
        default: {
          const gexColorDf = Store.state.account.User.gexagrammColor[obj.gate]
            ? Store.state.account.User.gexagrammColor[obj.gate]
            : "";
          const msgDf =
            '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
            '<h3 class="tooltip-inner-header-top"><span class="tooltips-title-hexagram" style="color:' +
            gexColorDf +
            '">' +
            obj.icon +
            "</span> " +
            obj.title +
            "</h3>" +
            obj.tooltip +
            '<div class="tooltip-inner-divider"></div>';
          Store.commit(
            "account/update_reg_go_text_before_btn_" + reportType,
            Store.state.conf.Messages.tooltip_partner
          );
          Store.commit(
            "account/update_btn_tooltip_name_" + reportType,
            "btn_tooltip_partner"
          );
          return msgDf;
        }
      }
    },
    getTooltipsDescMsg: (val, reportType) => {
      if (val in Store.state.account.User.chartBodygraph) {
        // let mkey1, mkey2, mkey3, mkey4, mkey5, mkey6, buttonType
        let mkey1, mkey2, mkey3, mkey4, mkey6, mkey7, buttonType;
        // console.log("reportType is: ", reportType);
        switch (reportType) {
          case 1:
            mkey1 = "tooltip_normal";
            mkey2 = "tooltip_notconfirmed";
            mkey3 = "tooltip_full";
            mkey4 = "tooltip_pro";
            mkey6 = "tooltip_buy_full";
            mkey7 = "tooltip_switch_on_full";
            buttonType = Store.state.conf.ConfApp.tooltip_button_type;
            break;
          case 2:
            mkey1 = "tooltip_composite_normal";
            mkey2 = "tooltip_composite_notconfirmed";
            mkey3 = "tooltip_composite_full";
            mkey4 = "tooltip_composite_pro";
            // mkey5 = "tooltip_composite_buy";
            mkey6 = "tooltip_buy_full";
            mkey7 = "tooltip_switch_on_full";
            buttonType = Store.state.conf.ConfApp.cp_tooltip_button_type;
            break;
          case 3:
            mkey1 = "tooltip_normal";
            mkey2 = "tooltip_notconfirmed";
            mkey3 = "tooltip_full";
            mkey4 = "tooltip_pro";
            mkey6 = "tooltip_buy_full";
            mkey7 = "tooltip_switch_on_full";
            buttonType = Store.state.conf.ConfApp.tr_tooltip_button_type;
            break;
          case 4:
            mkey1 = "tooltip_normal";
            mkey2 = "tooltip_notconfirmed";
            mkey3 = "tooltip_full";
            mkey4 = "tooltip_pro";
            mkey6 = "tooltip_buy_full";
            mkey7 = "tooltip_switch_on_full";
            buttonType = Store.state.conf.ConfApp.rt_tooltip_button_type;
            break;
        }
        // console.log("buttonType is: ", buttonType);
        switch (buttonType) {
          case 1: {
            const msg =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>';
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey1]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey1
            );
            return msg;
          }
          case 2: {
            Store.commit("account/UPDATE_IS_VISIBLE_TOOLTIP_BTN", false);
            const msg2 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-last-child"></div>';
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey2]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey2
            );
            return msg2;
          }
          case 3:
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey3]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey3
            );
            return (
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>'
            );
          case 4: {
            const msg4 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>';
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey4]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey4
            );
            return msg4;
          }
          case 6: {
            const msg6 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              // TOOLTIPS_FULL + // Заккоментил пока верхнее предложение подсказок (tooltip-inner-btn-offer)
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>';
            // Когда приходит 6, вверху tooltip-inner-divider временно не показываем, а внизу FULL-подсказки - 6
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey6]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey6
            );
            return msg6;
          }
          case 7: {
            const msg7 =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>';
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages[mkey7]
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_" + mkey7
            );
            return msg7;
          }
          default: {
            const msgDf =
              '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
              '<h3 class="tooltip-inner-header-top">' +
              Store.state.account.User.chartBodygraph[val].title +
              "</h3>" +
              Store.state.account.User.chartBodygraph[val].description +
              '<div class="tooltip-inner-divider"></div>';
            Store.commit(
              "account/update_reg_go_text_before_btn_" + reportType,
              Store.state.conf.Messages.tooltip_partner
            );
            Store.commit(
              "account/update_btn_tooltip_name_" + reportType,
              "btn_tooltip_partner"
            );
            return msgDf;
          }
        }
      } else {
        // Ошибка в названии ключа подсказки! Проверь Одминко!
        return "Что-то пошло не так...";
      }
    },
    getHelpTooltip: (key) => {
      return (
        '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
        '<h3 class="tooltip-inner-header-top">' +
        Store.state.conf.Messages["title_" + key] +
        "</h3>" +
        Store.state.conf.Messages[key] +
        "</div>"
      );
    },
    getColorTooltip: (key, prop) => {
      if (prop in Store.state.account.User.chartBodygraph[key]) {
        return (
          '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
          "<h3 class='tooltip-inner-header-top'>" +
          Store.state.account.User.chartBodygraph[key][prop].title +
          "</h3>" +
          // TOOLTIPS_FULL_OFFER_BUY_ALL + // Заккоментил пока верхнее предложение подсказок (tooltip-inner-btn-offer)
          Store.state.account.User.chartBodygraph[key][prop].tooltip +
          "</div>"
        );
      } else {
        return (
          '<div class="tooltip-close"><button type="button" class="v-btn v-btn--absolute v-btn--flat v-btn--icon v-btn--right v-btn--round theme--light v-size--default" onclick="clearTooltips()"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light"></i></span></button></div>' +
          '<h3 class="tooltip-inner-header-top">Контент в разработке...</h3></div>'
        );
      }
    },
    regGo: (prefix, text, extTips, btnName) => {
      return (
        "<div class=reg-go>" +
        text +
        '<button class=tooltip-btn-bg onclick="clickTooltipBtn' +
        prefix +
        "(" +
        extTips +
        ')">' +
        btnName +
        "</button></div>"
      );
    },
    // не стрелочная функция, чтобы передать контекст и вызвать scrollToTop()
    goToPath: function(path) {
      if (Router.history.current.path !== path) {
        Router.push(path);
      }
      setTimeout(() => {
        this.scrollToTop();
      }, 100);
    },
    scrollToTop: () => {
      const widgethead = document.getElementById("widget");
      const blockLocation = widgethead
        ? widgethead.getBoundingClientRect().top
        : 0;
      if (widgethead && blockLocation < 90) {
        widgethead.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
      // console.log("scrollToTop: ", blockLocation);
    },
    getMessage: (key) => {
      return Store.state.conf.Messages[key];
    },
    clearTooltips: () => {
      Store.commit("account/clear_open_tooltips", {});
    },
    clearAcPlanets: () => {
      for (let i = 0; i <= 12; i++) {
        Store.commit("account/set_is_planet_per", { planet: i, num: 0 });
        Store.commit("account/set_is_planet_des", { planet: i, num: 0 });
        Store.commit("account/set_is_planet_per2", { planet: i, num: 0 });
        Store.commit("account/set_is_planet_des2", { planet: i, num: 0 });
        Store.commit("account/set_is_planet_trs", { planet: i, num: 0 });
      }
      Store.commit("account/clear_gate_in_center_hover");
    },
    closeMenu: (gates, v) => {
      if (gates === "all") {
        Store.commit("account/set_is_harmonious_gates", v);
        Store.commit("account/set_is_love_gates", v);
        Store.commit("account/set_is_fears_gates", v);
        Store.commit("account/set_is_malancholy_gates", v);
        Store.commit("account/set_is_dream_gates", v);
      }
      if (gates === "harmonious") {
        Store.commit("account/set_is_harmonious_gates", v);
        Store.commit("account/set_is_love_gates", !v);
        Store.commit("account/set_is_fears_gates", !v);
        Store.commit("account/set_is_malancholy_gates", !v);
        Store.commit("account/set_is_dream_gates", !v);
      }
      if (gates === "love") {
        Store.commit("account/set_is_love_gates", v);
        Store.commit("account/set_is_harmonious_gates", !v);
        Store.commit("account/set_is_fears_gates", !v);
        Store.commit("account/set_is_malancholy_gates", !v);
        Store.commit("account/set_is_dream_gates", !v);
      }
      if (gates === "fears") {
        Store.commit("account/set_is_fears_gates", v);
        Store.commit("account/set_is_harmonious_gates", !v);
        Store.commit("account/set_is_love_gates", !v);
        Store.commit("account/set_is_malancholy_gates", !v);
        Store.commit("account/set_is_dream_gates", !v);
      }
      if (gates === "melancholy") {
        Store.commit("account/set_is_malancholy_gates", v);
        Store.commit("account/set_is_harmonious_gates", !v);
        Store.commit("account/set_is_love_gates", !v);
        Store.commit("account/set_is_fears_gates", !v);
        Store.commit("account/set_is_dream_gates", !v);
      }
      if (gates === "dream") {
        Store.commit("account/set_is_dream_gates", v);
        Store.commit("account/set_is_harmonious_gates", !v);
        Store.commit("account/set_is_love_gates", !v);
        Store.commit("account/set_is_fears_gates", !v);
        Store.commit("account/set_is_malancholy_gates", !v);
      }
      // console.log("this.$router: ", Router.history.current.path);
      if (Router.history.current.path !== "/bodygraph")
        Router.push("/bodygraph");
    },
    // Hardcode for reg_go text_before_btn_1 & btn_tooltip_1
    regGoTextAndBtn: () => {
      setTimeout(() => {
        Store.commit(
          "account/update_reg_go_text_before_btn_1",
          Store.state.conf.Messages["tooltip_full"]
        );
        Store.commit("account/update_btn_tooltip_name_1", "btn_tooltip_full");
      }, 2500);
    }
  }
};
