import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/bodygraph",
    name: "BodyGraph",
    component: () => import("../views/BodyGraph.vue")
  },
  {
    path: "/composite",
    name: "Composite",
    component: () => import("../views/BodyGraphComposite.vue")
  },
  {
    path: "/transit",
    name: "Transit",
    component: () => import("../views/BodyGraphTransit.vue")
  },
  {
    path: "/returns",
    name: "Returns",
    component: () => import("../views/Returns.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue")
  },
  {
    path: "/books",
    name: "Books",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Books.vue")
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue")
  },
  {
    path: "/consultations",
    name: "Consultations",
    component: () => import("../views/Consultations.vue")
  },
  {
    path: "/feed",
    name: "Feed",
    component: () => import("../views/Feed.vue")
  },
  {
    path: "/chats",
    name: "Chats",
    component: () => import("../views/Chats.vue")
  },
  {
    path: "/dating",
    name: "Dating",
    component: () => import("../views/Dating.vue")
  },
  {
    path: "/socialprofile",
    name: "SocialProfile",
    component: () => import("../views/SocialProfile.vue")
  },
  {
    path: "/post",
    name: "Post",
    component: () => import("../components/community/posts/post.vue")
  },
  {
    path: "/reset",
    name: "Reset password",
    component: () => import("../views/Reset.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
