import Api from "@/api/api";
import Router from "@/router";
// import { commonErrorsMethods } from "@/mixins/common-errors-methods";

// initial state
const state = () => ({
  User: {
    id: null,
    name: "",
    another_name: "",
    age: "",
    email: "",
    activeNameTab: "Registration",
    city: "",
    cityid: null,
    lastCities: [],
    day: { day: "15" },
    month: { month: "Июнь (6)", id: "06" },
    year: { year: "1990" },
    hour: { hour: "12" },
    minute: { minute: "30" },
    isLogged: false,
    access_token: "human-design-token",
    reportsHistory: {
      reports: [],
      pageCount: 0,
      totalCount: 0,
      dialogStatus: false
    },
    compositesHistory: {
      reports: [],
      pageCount: 0,
      totalCount: 0,
      dialogStatus: false
    },
    chartBodygraph: null,
    bodyGraphName: "",
    bodyGraphAge: "",
    bodyGraphCity: "",
    toolTips: {},
    RegGoTextBeforeBtn: "",
    btnTooltipName: "",
    RegGoTextBeforeBtnComposite: "",
    btnTooltipNameComposite: "",
    isVisibleTooltipBtn: true,
    is_subscribed_to_justclick: false,
    products: [],
    gexagrammColor: {
      1: "#e8dc28",
      2: "#e8dc28",
      3: "#de0052",
      4: "#61ad42",
      5: "#de0052",
      6: "#c45808",
      7: "#e8dc28",
      8: "#49c4be",
      9: "#de0052",
      10: "#e8dc28",
      11: "#61ad42",
      12: "#49c4be",
      13: "#e8dc28",
      14: "#de0052",
      15: "#e8dc28",
      16: "#49c4be",
      17: "#61ad42",
      18: "#c45808",
      19: "#c45808",
      20: "#49c4be",
      21: "#de0052",
      22: "#c45808",
      23: "#49c4be",
      24: "#61ad42",
      25: "#e8dc28",
      26: "#de0052",
      27: "#de0052",
      28: "#c45808",
      29: "#de0052",
      30: "#c45808",
      31: "#49c4be",
      32: "#c45808",
      33: "#49c4be",
      34: "#de0052",
      35: "#49c4be",
      36: "#c45808",
      37: "#c45808",
      38: "#c45808",
      39: "#c45808",
      40: "#de0052",
      41: "#c45808",
      42: "#de0052",
      43: "#61ad42",
      44: "#c45808",
      45: "#49c4be",
      46: "#e8dc28",
      47: "#61ad42",
      48: "#c45808",
      49: "#c45808",
      50: "#c45808",
      51: "#de0052",
      52: "#c45808",
      53: "#c45808",
      54: "#c45808",
      55: "#c45808",
      56: "#49c4be",
      57: "#c45808",
      58: "#c45808",
      59: "#de0052",
      60: "#c45808",
      61: "#e8dc28",
      62: "#49c4be",
      63: "#e8dc28",
      64: "#e8dc28"
    },
    reportid: "",
    reportNameRod: "",
    user_products_id: "",
    report_hash: "",
    is_user_product: "",
    is_user_product_hash: false,
    reportidPro: "",
    is_stored: 0,
    demo_reports: 0,
    isProTooltips: false,
    haveIsUsedUserProducts: false,
    haveIsOneUnusedUserProducts: false,
    haveIsUsedUserComposites: false,
    haveIsUsedProReports: false,
    haveIsUsedChildProducts: false,
    haveIsUsedCareerProducts: false,
    reference_code: "",
    ext_reference_code: "",
    current_balance: 0,
    total_income: 0,
    min_withdrawal: 4000,
    slider: 0,
    referrals_count: 0,
    referrals_ctr: "",
    no_interesting: false,
    no_interesting_in_social_profile: false,
    was_open_add_post: false,
    was_switched: false,
    isTooltipsOfferbuy: true,
    isTooltipsOfferbuyBtnOk: true,
    share_reportid: "",
    pin_request: false,
    registered: false,
    is_admin: false,
    is_subscriber: false,
    new_messages_count: 0,
    deviceOs: "web",
    isHarmoniousGates: false,
    isLoveGates: false,
    isFearsGates: false,
    isMelancholyGates: false,
    isDreamGates: false,
    pathHistory: 0,
    raves_count: 0,
    community: {
      age: "",
      avatar: "",
      day: null,
      month: null,
      year: null,
      hour: null,
      minute: null,
      chart_type: "",
      chart_profile: "",
      city: "",
      cityid: null,
      city2id: null,
      cities: [],
      cities2: [],
      slogan: "",
      about: "",
      why_am_i_here: "",
      hd_level: "",
      weight: "не указан",
      height: "не указан",
      isImagesValidation: true,
      posts: [],
      posts_method: "posts",
      post: {},
      posts_options: {},
      edit_post_idx: null,
      delete_post_id: null,
      block_post_id: null,
      block_post_status: null,
      post_spam_id: null,
      post_spam_dialog: false,
      page: 1,
      categories: [],
      show_my_city_posts_only: 0,
      total_post_pages: 0,
      total_my_post_pages: 0,
      total_favourites_pages: 0,
      comments: [],
      loading_posts: true,
      followers_count: 0,
      following_count: 0,
      socialSteppers: true,
      beforePosting: true,
      beforePostingDialog: false,
      beforeEditingProfile: true,
      beforeEditingProfileDialog: false,
      beforeEditingUserImages: true,
      beforeEditingUserImagesDialog: false,
      beforeSelector: true,
      beforeSelectorDialog: false,
      newMessagesCount: 0,
      chatUsers: [],
      targets: [],
      target_select: [],
      viewId: null,
      getPostsUserId: null,
      selected_user: {},
      promoCodeUrl: "",
      sex: null,
      sexselect: [],
      marital_status: null,
      marital_statuses: [],
      children: [],
      children_select: [],
      visited_countries: "",
      i_understand: "",
      callUserId: null,
      searchResult: [],
      searchMetaResult: {},
      searchNextPage: 1,
      searchVocabulary: {
        chart_types: [],
        chart_authorities: [],
        chart_centers: [],
        chart_channels: [],
        chart_definitions: [],
        chart_gates: [],
        chart_profiles: [],
        chart_quarters: []
      },
      chats: {
        onlineUserIDs: [],
        ApiUsers: [],
        selectedUserMessages: [],
        newListUser: {},
        isWritingUserId: null
      },
      my_profile: {
        name: "",
        age: "",
        avatar: "",
        day: null,
        month: null,
        year: null,
        hour: null,
        minute: null,
        chart_type: "",
        chart_profile: "",
        city: "",
        city2: "",
        cityid: null,
        city2id: null,
        cities: [],
        cities2: [],
        images: [],
        slogan: "",
        about: "",
        why_am_i_here: "",
        hd_level: "",
        weight: "не указан",
        height: "не указан",
        sex: null,
        sexselect: [],
        marital_status: null,
        children: [],
        visited_countries: "",
        i_understand: "",
        guests_count: 0,
        targets: [],
        has_avatar: false, // нужно выпилить
        has_bodygraph: false, // нужно выпилить
        can_add_content_to_social_network: false
      },
      isConversationCalledFromProfile: false,
      isConversationCalledFromChats: false,
      edit_social_profile: false
    }
  },
  Transit: {
    day: { day: "15" },
    month: { month: "Июнь (6)", id: "06" },
    year: { year: "1990" },
    hour: { hour: "12" },
    minute: { minute: "30" },
    cityid: 18009,
    lastCities: [
      {
        id: 18009,
        title: "Москва",
        country: { id: 192, iso: "RU", title: "Россия" }
      }
    ],
    utc: "+3",
    chartBodygraphTransit: null,
    chartBodygraphChart: null,
    report_id: "",
    transit_hash: "",
    infliction_hash: "",
    toolTips: {}
  },
  ext_transit_hash: "",
  ext_infliction_hash: "",
  Composite: {
    chartBodygraphChart: null,
    chartBodygraphChart2: null,
    chartBodygraphConnection: null,
    is_stored: 0,
    method: "",
    composite_hash: "",
    report_id: "",
    chartBodygraphChartName: "",
    chartBodygraphChartName2: "",
    mkey: {
      1: "tooltip_composite_normal",
      2: "tooltip_composite_notconfirmed",
      3: "tooltip_composite_full",
      // 4: "tooltip_composite_pro",
      4: "tooltip_buy_full",
      5: "tooltip_composite_buy",
      6: "tooltip_buy_full",
      7: "tooltip_switch_on_full"
    }
  },
  Return: {
    chartBodygraphChart: null,
    chartBodygraphChart2: null,
    chartBodygraphConnection: null,
    report_id: "",
    return_hash: ""
  },
  ext_return_hash: "",
  centerToGates: {
    1: [19, 38, 39, 41, 52, 53, 54, 58, 60],
    2: [6, 22, 30, 36, 37, 49, 55],
    3: [3, 5, 9, 14, 27, 29, 34, 42, 59],
    4: [18, 28, 32, 44, 48, 50, 57],
    5: [21, 26, 40, 51],
    6: [1, 2, 7, 10, 13, 15, 25, 46],
    7: [8, 12, 16, 20, 23, 31, 33, 35, 45, 56, 62],
    8: [4, 11, 17, 24, 43, 47],
    9: [61, 63, 64]
  },
  openTooltips: {},
  gateLinesCenterHover: [],
  gateInCenterHover: [],
  isPlanetDes: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  },
  isPlanetPer: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  },
  isPlanetDes2: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  },
  isPlanetPer2: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  },
  isPlanetTrs: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0
  },
  dateTime: {
    days: [
      { day: "01" },
      { day: "02" },
      { day: "03" },
      { day: "04" },
      { day: "05" },
      { day: "06" },
      { day: "07" },
      { day: "08" },
      { day: "09" },
      { day: "10" },
      { day: "11" },
      { day: "12" },
      { day: "13" },
      { day: "14" },
      { day: "15" },
      { day: "16" },
      { day: "17" },
      { day: "18" },
      { day: "19" },
      { day: "20" },
      { day: "21" },
      { day: "22" },
      { day: "23" },
      { day: "24" },
      { day: "25" },
      { day: "26" },
      { day: "27" },
      { day: "28" },
      { day: "29" },
      { day: "30" },
      { day: "31" }
    ],
    months: [
      { month: "Январь (1)", id: "01" },
      { month: "Февраль (2)", id: "02" },
      { month: "Март (3)", id: "03" },
      { month: "Апрель (4)", id: "04" },
      { month: "Май (5)", id: "05" },
      { month: "Июнь (6)", id: "06" },
      { month: "Июль (7)", id: "07" },
      { month: "Август (8)", id: "08" },
      { month: "Сентябрь (9)", id: "09" },
      { month: "Октябрь (10)", id: "10" },
      { month: "Ноябрь (11)", id: "11" },
      { month: "Декабрь (12)", id: "12" }
    ],
    years: [
      { year: "2035" },
      { year: "2034" },
      { year: "2033" },
      { year: "2032" },
      { year: "2031" },
      { year: "2030" },
      { year: "2029" },
      { year: "2028" },
      { year: "2027" },
      { year: "2026" },
      { year: "2025" },
      { year: "2024" },
      { year: "2023" },
      { year: "2022" },
      { year: "2021" },
      { year: "2020" },
      { year: "2019" },
      { year: "2018" },
      { year: "2017" },
      { year: "2016" },
      { year: "2015" },
      { year: "2014" },
      { year: "2013" },
      { year: "2012" },
      { year: "2011" },
      { year: "2010" },
      { year: "2009" },
      { year: "2008" },
      { year: "2007" },
      { year: "2006" },
      { year: "2005" },
      { year: "2004" },
      { year: "2003" },
      { year: "2002" },
      { year: "2001" },
      { year: "2000" },
      { year: "1999" },
      { year: "1998" },
      { year: "1997" },
      { year: "1996" },
      { year: "1995" },
      { year: "1994" },
      { year: "1993" },
      { year: "1992" },
      { year: "1991" },
      { year: "1990" },
      { year: "1989" },
      { year: "1988" },
      { year: "1987" },
      { year: "1986" },
      { year: "1985" },
      { year: "1984" },
      { year: "1983" },
      { year: "1982" },
      { year: "1981" },
      { year: "1980" },
      { year: "1979" },
      { year: "1978" },
      { year: "1977" },
      { year: "1976" },
      { year: "1975" },
      { year: "1974" },
      { year: "1973" },
      { year: "1972" },
      { year: "1971" },
      { year: "1970" },
      { year: "1969" },
      { year: "1968" },
      { year: "1967" },
      { year: "1966" },
      { year: "1965" },
      { year: "1964" },
      { year: "1963" },
      { year: "1962" },
      { year: "1961" },
      { year: "1960" },
      { year: "1959" },
      { year: "1958" },
      { year: "1957" },
      { year: "1956" },
      { year: "1955" },
      { year: "1954" },
      { year: "1953" },
      { year: "1952" },
      { year: "1951" },
      { year: "1950" },
      { year: "1949" },
      { year: "1948" },
      { year: "1947" },
      { year: "1946" },
      { year: "1945" },
      { year: "1944" },
      { year: "1943" },
      { year: "1942" },
      { year: "1941" },
      { year: "1940" },
      { year: "1939" },
      { year: "1938" },
      { year: "1937" },
      { year: "1936" },
      { year: "1935" },
      { year: "1934" },
      { year: "1933" },
      { year: "1932" },
      { year: "1931" },
      { year: "1930" },
      { year: "1929" },
      { year: "1928" },
      { year: "1927" },
      { year: "1926" },
      { year: "1925" },
      { year: "1924" },
      { year: "1923" },
      { year: "1922" },
      { year: "1921" },
      { year: "1920" },
      { year: "1929" },
      { year: "1928" },
      { year: "1927" },
      { year: "1926" },
      { year: "1925" },
      { year: "1924" },
      { year: "1923" },
      { year: "1922" },
      { year: "1921" },
      { year: "1920" }
    ],
    hours: [
      { hour: "00" },
      { hour: "01" },
      { hour: "02" },
      { hour: "03" },
      { hour: "04" },
      { hour: "05" },
      { hour: "06" },
      { hour: "07" },
      { hour: "08" },
      { hour: "09" },
      { hour: "10" },
      { hour: "11" },
      { hour: "12" },
      { hour: "13" },
      { hour: "14" },
      { hour: "15" },
      { hour: "16" },
      { hour: "17" },
      { hour: "18" },
      { hour: "19" },
      { hour: "20" },
      { hour: "21" },
      { hour: "22" },
      { hour: "23" }
    ],
    minutes: [
      { minute: "00" },
      { minute: "01" },
      { minute: "02" },
      { minute: "03" },
      { minute: "04" },
      { minute: "05" },
      { minute: "06" },
      { minute: "07" },
      { minute: "08" },
      { minute: "09" },
      { minute: "10" },
      { minute: "11" },
      { minute: "12" },
      { minute: "13" },
      { minute: "14" },
      { minute: "15" },
      { minute: "16" },
      { minute: "17" },
      { minute: "18" },
      { minute: "19" },
      { minute: "20" },
      { minute: "21" },
      { minute: "22" },
      { minute: "23" },
      { minute: "24" },
      { minute: "25" },
      { minute: "26" },
      { minute: "27" },
      { minute: "28" },
      { minute: "29" },
      { minute: "30" },
      { minute: "31" },
      { minute: "32" },
      { minute: "33" },
      { minute: "34" },
      { minute: "35" },
      { minute: "36" },
      { minute: "37" },
      { minute: "38" },
      { minute: "39" },
      { minute: "40" },
      { minute: "41" },
      { minute: "42" },
      { minute: "43" },
      { minute: "44" },
      { minute: "45" },
      { minute: "46" },
      { minute: "47" },
      { minute: "48" },
      { minute: "49" },
      { minute: "50" },
      { minute: "51" },
      { minute: "52" },
      { minute: "53" },
      { minute: "54" },
      { minute: "55" },
      { minute: "56" },
      { minute: "57" },
      { minute: "58" },
      { minute: "59" }
    ]
  },
  Books: [],
  Products: [],
  Profile: {
    partner_info_text: "",
    full_tooltips_count: 0,
    full_composite_tooltips_count: 0,
    is_full_tooltips_usage_enabled: false
  },
  firstBgDialog: false,
  progresStatus: false
});

// getters
const getters = {
  GET_TOKEN: (state) => {
    return state.User.access_token;
  },
  is_logged: (state) => {
    return state.User.isLogged;
  },
  books: (state) => {
    return state.Books;
  },
  IS_EMAIL_CONFIRMED: (state) => {
    return state.User.is_email_confirmed;
  },
  IS_UNDER_CONSTRUCTION: (state) => {
    return state.User.isUnderConstruction;
  },
  IS_UNDER_CONSTRUCTION_COMPOSITE: (state) => {
    return state.User.isUnderConstructionComosite;
  },
  IS_UNDER_CONSTRUCTION_COMPOSITE_TOOLTIPS: (state) => {
    return state.User.isUnderConstructionComositeTooltips;
  },
  IS_UNDER_CONSTRUCTION_TRANSIT: (state) => {
    return state.User.isUnderConstructionTransit;
  },
  get_posts: (state) => {
    return state.User.community.posts;
  },
  get_current_page: (state) => {
    return state.User.community.page;
  },
  get_total_post_pages: (state) => {
    return state.User.community.total_post_pages;
  },
  loading_posts: (state) => {
    return state.User.community.loading_posts;
  },
  post_spam_id: (state) => {
    return state.User.community.post_spam_id;
  },
  post_spam_dialog: (state) => {
    return state.User.community.post_spam_dialog;
  },
  post_block_id: (state) => {
    return state.User.community.block_post_id;
  },
  post_block_status: (state) => {
    return state.User.community.block_post_status;
  },
  posts_filters: (state) => {
    return state.User.community.categories;
  },
  can_add_content_to_social_network: (state) => {
    return state.User.community.my_profile.can_add_content_to_social_network;
  },
  post_del_id: (state) => {
    return state.User.community.delete_post_id;
  },
  post: (state) => {
    return state.User.community.post;
  },
  comments: (state) => {
    return state.User.community.comments;
  },
  get_chat_users: (state) => {
    return state.User.community.chatUsers;
  },
  users_from_api: (state) => {
    return state.User.community.chats.ApiUsers;
  },
  new_list_user: (state) => {
    return state.User.community.chats.newListUser;
  }
};

// actions
const actions = {
  async getLogin({ commit, dispatch, rootState, state }, data) {
    try {
      const result = await Api.login(data, rootState.conf.http_options);
      if (result.success) {
        localStorage.removeItem("user_id");
        localStorage.removeItem("name");
        localStorage.removeItem("namereg");
        localStorage.removeItem("email");
        localStorage.removeItem("authToken");
        localStorage.removeItem("is_subscribed_to_justclick");
        localStorage.removeItem("userProductId");
        localStorage.removeItem("userProductHash");
        state.User.user_products_id = "";
        state.User.is_user_product_hash = false;
        if (result.profile.first_name) {
          localStorage.setItem("name", result.profile.first_name);
          localStorage.setItem("namereg", result.profile.first_name);
        }
        if (result.profile.id) {
          localStorage.setItem("user_id", result.profile.id);
          state.User.id = +result.profile.id;
        }
        localStorage.setItem("email", result.profile.email);
        state.User.email = result.profile.email;
        localStorage.setItem("authToken", result.access_token);
        localStorage.setItem(
          "is_subscribed_to_justclick",
          result.profile.is_subscribed_to_justclick
        );
        if (
          localStorage.getItem("authToken") &&
          localStorage.getItem("is_subscribed_to_justclick")
        ) {
          if (localStorage.getItem("is_subscribed_to_justclick") === "true")
            state.User.registered = true;
          state.User.access_token = result.access_token;
          rootState.conf.http_options.headers["X-Api-Key"] =
            result.access_token;
          state.User.is_subscribed_to_justclick =
            result.profile.is_subscribed_to_justclick;
          state.User.name = result.profile.first_name;
          state.User.name = localStorage.getItem("namereg");
          commit("login_success", true);
          localStorage.setItem("regTab", 1);
          commit("forms/setLogSuccess", "Авторизация прошла успешно", {
            root: true
          });
          // commit("forms/closeDialog", {}, { root: true });
          // Обновление report по reportHash если есть в localstorage
          if (localStorage.getItem("reportHash")) {
            dispatch("getRaveReportByHash", localStorage.getItem("reportHash"));
          }
          // Обновление reportsHistory & compositesHistory
          dispatch("getRaveReports");
          dispatch("getCompositeReports");
          // Запрос и обновление социального профиля
          if (state.User.isLogged) {
            dispatch("getSocialProfile");
          }
          // Restoring Before Posting (Social)
          if (localStorage.getItem("before_posting")) {
            commit("update_before_posting", false);
          }
          // Редирект после логина на форму расчета если был в UI сброса пароля
          if (Router.history.current.path === "/reset") {
            Router.push("/");
          }
          //  Редирект по route.path если он передается пропсом
          if (data.path && Router.history.current.path !== data.path) {
            Router.push(data.path);
          }
        }
      }
    } catch (e) {
      if (e.message.includes("401") || e.message.includes("Network Error")) {
        localStorage.removeItem("authToken");
        commit("update_token", "human-design-token");
        rootState.conf.http_options.headers["X-Api-Key"] = "human-design-token";
        commit("forms/setLogErrors", "Сетевая ошибка, попробуйте еще раз...", {
          root: true
        });
        localStorage.removeItem("reportId");
        localStorage.removeItem("chartBodygraph");
        commit("update_report_id", "");
        commit("update_chart_bodygraph", null);
        commit("login_success", false);
        commit("conf/update_logout_dialog", true, { root: true });
      }
      dispatch("errorHandler", e, { root: true });
      // this.logErrors = e.response.data.errors.password[0];
      commit("forms/setLogErrors", e.response.data.errors.password[0], {
        root: true
      });
    }
  },
  async getRegistration({ commit, dispatch, rootState /*, state */ }, data) {
    try {
      const result = await Api.register(data, rootState.conf.http_options);
      if (result.success) {
        localStorage.removeItem("name");
        localStorage.removeItem("namereg");
        localStorage.removeItem("authToken");
        localStorage.removeItem("is_subscribed_to_justclick");
        if (result.profile.first_name) {
          localStorage.setItem("name", result.profile.first_name);
          localStorage.setItem("namereg", result.profile.first_name);
        }
        localStorage.setItem("email", result.profile.email);
        localStorage.setItem("authToken", result.access_token);
        localStorage.setItem(
          "is_subscribed_to_justclick",
          result.profile.is_subscribed_to_justclick
        );
        localStorage.setItem("regTab", JSON.stringify(1));
        if (
          localStorage.getItem("authToken") &&
          localStorage.getItem("is_subscribed_to_justclick")
        ) {
          commit("update_token", result.access_token);
          commit("update_justclick", result.profile.is_subscribed_to_justclick);
          localStorage.setItem("user_id", result.profile.id);
          commit("update_user_id", +result.profile.id);
          commit("update_name", result.profile.first_name);
          commit("update_email", result.profile.email);
          commit("login_success", true);
          commit("get_body_graph_just_click", "get");
          /* if (state.Transit.chartBodygraphChart) {
            commit("GET_BODY_GRAPH_TRANSIT_CHART");
          } else {
            commit("GET_BODY_GRAPH_TRANSIT");
          } */
        }
        // Обновление report по reportHash если есть в localstorage
        if (localStorage.getItem("reportHash")) {
          dispatch("getRaveReportByHash", localStorage.getItem("reportHash"));
        }
        // Обновление профайлов в зависимости от раздела
        /* if (!rootState.ConfApp.renderCommunity) {
          commit("CALL_UPDATE_PROFILE_FULL_TOOLTIPS_COUNT");
          this.$goTab(state.ConfApp.tab);
        } else {
          commit("SET_RE_RENDER_COMPONENT", {
            r: false,
            c: true
          });
          commit("CALL_UPDATE_SOCIAL_PROFILE");
          this.$router.push({
            path: "/",
            query: { community: "1" }
          });
          // this.$goCommTab(0);
        } */
        commit(
          "form/setRegSuccess",
          "Спасибо за регистрацию! На ваш email было направлено письмо с дальнейшими инструкциями"
        );
      } else {
        commit("forms/setRegErrors", result.errors.email[0], {
          root: true
        });
      }
    } catch (e) {
      commit("forms/setRegErrors", e.response.data.errors.email[0], {
        root: true
      });
      // dispatch("errorHandler", e, { root: true });
      //commit("forms/closeRegDialog", {}, { root: true });
    }
  },
  async getRaveReports({ commit, rootState, dispatch }) {
    try {
      const result = await Api.getRaveReports({}, rootState.conf.http_options);
      if (result.reports.length) {
        commit("set_reports_history", result.reports);
        commit("set_reports_history_count", result._meta.totalCount);
        commit("set_reports_history_page_count", result._meta.pageCount);
        // Store reports & etc to localstorage
        const reportsHistory = {
          reports: result.reports,
          pageCount: result._meta.pageCount,
          totalCount: result._meta.totalCount,
          dialogStatus: false
        };
        localStorage.setItem("raveReports", JSON.stringify(reportsHistory));
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getCompositeReports({ commit, rootState, dispatch }) {
    try {
      const page = 1;
      const result = await Api.getCompositeReports(
        page,
        rootState.conf.http_options
      );
      if (result.reports.length) {
        commit("set_composites_history", result.reports);
        commit("set_composites_history_count", result._meta.totalCount);
        commit("set_composites_history_page_count", result._meta.pageCount);
        // Store reports & etc to localstorage
        const compositesHistory = {
          reports: result.reports,
          pageCount: result._meta.pageCount,
          totalCount: result._meta.totalCount,
          dialogStatus: false
        };
        localStorage.setItem(
          "compositeReports",
          JSON.stringify(compositesHistory)
        );
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getBooks({ rootState, state, dispatch }) {
    try {
      const result = await Api.getBooks(rootState.conf.http_options);
      if (result.success) {
        state.Books = result.books;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getProducts({ rootState, state, dispatch }) {
    try {
      const result = await Api.getProducts(rootState.conf.http_options);
      if (result.success) {
        state.Products = result.products;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getProfile({ rootState, state, commit, dispatch }) {
    try {
      const result = await Api.getProfile({}, rootState.conf.http_options);
      if (result.success) {
        state.Profile = result.profile;
        if (result.profile.is_admin) {
          commit("detect_admin", result.profile.is_admin);
        }
        if (result.profile.is_subscriber) {
          commit("email_notifications", true);
        }
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async createRaveReport({ rootState, commit, dispatch }, data) {
    try {
      const result = await Api.createRaveReport(
        data,
        rootState.conf.http_options
      );
      if (result.success) {
        // console.log("createRaveReport result: ", result);
        localStorage.setItem(
          "chartBodygraph",
          JSON.stringify(result.report.body_graph)
        );
        commit("update_chart_bodygraph", result.report.body_graph);
        // console.log("result.report.first_name: ", result.report.first_name);
        localStorage.setItem("bodygraph_name", result.report.first_name);
        commit("update_chart_bodygraph_name", result.report.first_name);
        localStorage.setItem(
          "bodygraph_age",
          JSON.stringify(result.report.age)
        );
        commit("update_chart_bodygraph_age", result.report.age);
        localStorage.setItem(
          "bodygraph_city",
          JSON.stringify(result.report.city.title)
        );
        commit("update_chart_bodygraph_city", result.report.city.title);
        localStorage.setItem("reportId", result.report.id);
        commit("update_report_id", result.report.id);
        localStorage.setItem(
          "reportNameRod",
          result.report.first_name_rod ? result.report.first_name_rod : ""
        );
        commit("update_report_name_rod", result.report.first_name_rod);
        localStorage.setItem("reportHash", result.report.report_hash);
        commit("update_report_hash", result.report.report_hash);
        localStorage.setItem("is_stored", result.report.is_stored);
        commit("update_is_stored", result.report.is_stored);
        localStorage.setItem(
          "tooltip_button_type",
          result.report.tooltip_button_type
        );
        commit(
          "conf/update_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  // Remove User Product ID/Hash
  removeUserProduct({ commit }) {
    localStorage.removeItem("userProductHash");
    commit("is_user_product_hash", false);
    localStorage.removeItem("reportHash");
    commit("update_report_hash", "");
    localStorage.removeItem("userProductId");
    commit("update_user_product_id", "");
  },
  async getRaveReportById({ rootState, commit, dispatch }, id) {
    try {
      const result = await Api.getRaveReport(id, rootState.conf.http_options);
      if (result.success) {
        // console.log("getRaveReport result: ", result);
        localStorage.setItem(
          "chartBodygraph",
          JSON.stringify(result.report.body_graph)
        );
        commit("update_chart_bodygraph", result.report.body_graph);
        // console.log("result.report.first_name: ", result.report.first_name);
        localStorage.setItem("bodygraph_name", result.report.first_name);
        commit("update_chart_bodygraph_name", result.report.first_name);
        localStorage.setItem(
          "bodygraph_age",
          JSON.stringify(result.report.age)
        );
        commit("update_chart_bodygraph_age", result.report.age);
        /* console.log(
          "getRaveReport report.city.title: ",
          result.report.city.title
        ); */
        localStorage.setItem("bodygraph_city", result.report.city.title);
        commit("update_chart_bodygraph_city", result.report.city.title);
        localStorage.setItem("reportId", result.report.id);
        commit("update_report_id", result.report.id);
        localStorage.setItem(
          "reportNameRod",
          result.report.first_name_rod ? result.report.first_name_rod : ""
        );
        commit("update_report_name_rod", result.report.first_name_rod);
        localStorage.setItem("reportHash", result.report.report_hash);
        commit("update_report_hash", result.report.report_hash);
        localStorage.setItem("is_stored", result.report.is_stored);
        commit("update_is_stored", result.report.is_stored);
        localStorage.setItem(
          "tooltip_button_type",
          result.report.tooltip_button_type
        );
        commit(
          "conf/update_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getRaveReportByHash({ rootState, commit, dispatch }, hash) {
    try {
      const result = await Api.getSharedRaveReport(
        hash,
        rootState.conf.http_options
      );
      if (result.success) {
        // console.log("getSharedRaveReport result: ", result);
        localStorage.setItem(
          "chartBodygraph",
          JSON.stringify(result.report.body_graph)
        );
        commit("update_chart_bodygraph", result.report.body_graph);
        // console.log("result.report.first_name: ", result.report.first_name);
        localStorage.setItem("bodygraph_name", result.report.first_name);
        commit("update_chart_bodygraph_name", result.report.first_name);
        localStorage.setItem(
          "bodygraph_age",
          JSON.stringify(result.report.age)
        );
        commit("update_chart_bodygraph_age", result.report.age);
        localStorage.setItem(
          "bodygraph_city",
          JSON.stringify(result.report.city.title)
        );
        commit("update_chart_bodygraph_city", result.report.city.title);
        localStorage.setItem("reportId", result.report.id);
        commit("update_report_id", result.report.id);
        localStorage.setItem(
          "reportNameRod",
          result.report.first_name_rod ? result.report.first_name_rod : ""
        );
        commit("update_report_name_rod", result.report.first_name_rod);
        localStorage.setItem("reportHash", result.report.report_hash);
        commit("update_report_hash", result.report.report_hash);
        localStorage.setItem("is_stored", result.report.is_stored);
        commit("update_is_stored", result.report.is_stored);
        localStorage.setItem(
          "tooltip_button_type",
          result.report.tooltip_button_type
        );
        commit(
          "conf/update_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getReportFromUserProductById({ rootState, commit, dispatch }, id) {
    try {
      const result = await Api.getReportFromUserProduct(
        id,
        rootState.conf.http_options
      );
      if (result.success) {
        // console.log("getReportFromUserProductById result: ", result);
        localStorage.setItem(
          "chartBodygraph",
          JSON.stringify(result.report.body_graph)
        );
        commit("update_chart_bodygraph", result.report.body_graph);
        // console.log("result.report.first_name: ", result.report.first_name);
        localStorage.setItem("bodygraph_name", result.report.first_name);
        commit("update_chart_bodygraph_name", result.report.first_name);
        localStorage.setItem(
          "bodygraph_age",
          JSON.stringify(result.report.age)
        );
        commit("update_chart_bodygraph_age", result.report.age);
        localStorage.setItem(
          "bodygraph_city",
          JSON.stringify(result.report.city.title)
        );
        commit("update_chart_bodygraph_city", result.report.city.title);
        localStorage.setItem("reportId", result.report.id);
        commit("update_report_id", result.report.id);
        localStorage.setItem(
          "reportNameRod",
          result.report.first_name_rod ? result.report.first_name_rod : ""
        );
        commit("update_report_name_rod", result.report.first_name_rod);
        localStorage.setItem("reportHash", result.report.report_hash);
        commit("update_report_hash", result.report.report_hash);
        localStorage.setItem(
          "tooltip_button_type",
          result.report.tooltip_button_type
        );
        commit(
          "conf/update_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getCompositeReportById({ rootState, state, commit, dispatch }, id) {
    // , state }, id) {
    try {
      const result = await Api.getCompositeReport(
        id,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_chart_bodygraph_composite", result.report);
        // console.log("update_chart_bodygraph_composite: ", state.Composite);
        commit("update_composite_is_stored", result.report.is_stored);
        // Store tooltips_button_type & get reg_go_text_before_btn & get btn_tooltip_name
        commit(
          "conf/update_cp_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        commit(
          "update_reg_go_text_before_btn_2",
          rootState.conf.Messages[
            state.Composite.mkey[result.report.tooltip_button_type]
          ]
        );
        commit(
          "update_btn_tooltip_name_2",
          "btn_" + state.Composite.mkey[result.report.tooltip_button_type]
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getCompositeByHash({ rootState, state, commit, dispatch }, hash) {
    // , state }, hash) {
    try {
      const result = await Api.getSharedCompositeReport(
        hash,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_chart_bodygraph_composite", result.report);
        // console.log("update_chart_bodygraph_composite: ", state.Composite);
        commit("update_composite_is_stored", result.report.is_stored);
        // Store tooltips_button_type & get reg_go_text_before_btn & get btn_tooltip_name
        commit(
          "conf/update_cp_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        commit(
          "update_reg_go_text_before_btn_2",
          rootState.conf.Messages[
            state.Composite.mkey[result.report.tooltip_button_type]
          ]
        );
        commit(
          "update_btn_tooltip_name_2",
          "btn_" + state.Composite.mkey[result.report.tooltip_button_type]
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async createCompositeReport({ commit, rootState, state, dispatch }, data) {
    try {
      const result = await Api.createComposite(
        data,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_chart_bodygraph_composite", result.report);
        commit("update_composite_is_stored", result.report.is_stored);
        // Store tooltips_button_type & get reg_go_text_before_btn & get btn_tooltip_name
        commit(
          "conf/update_cp_tooltips_button_type",
          result.report.tooltip_button_type,
          {
            root: true
          }
        );
        commit(
          "update_reg_go_text_before_btn_2",
          rootState.conf.Messages[
            state.Composite.mkey[result.report.tooltip_button_type]
          ]
        );
        commit(
          "update_btn_tooltip_name_2",
          "btn_" + state.Composite.mkey[result.report.tooltip_button_type]
        );
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getTransitByHash({ rootState, commit, dispatch, state }, hash) {
    try {
      const result = await Api.getSharedTransitReport(
        hash,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_transit_day", {
          day: result.report.birthday.slice(8, 10)
        });
        commit(
          "update_transit_month",
          state.dateTime.months[+result.report.birthday.slice(5, 7) - 1]
        );
        commit("update_transit_year", {
          year: result.report.birthday.slice(0, 4)
        });
        commit("update_transit_hour", {
          hour: result.report.birthday.slice(11, 13)
        });
        commit("update_transit_minute", {
          minute: result.report.birthday.slice(14)
        });
        dispatch("utcTimeZoneForrmatter", result.report.city.timezone);
        commit("update_transit_lc", [result.report.city]);
        commit("update_chart_bodygraph_transit", result.report.transit);
        commit("update_transit_city_id", result.report.city.id);
        commit("update_transit_report_id", result.report.id);
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async shareReport({ rootState, commit, dispatch }, data) {
    // console.log("DATA: ", data);
    try {
      commit("conf/set_share_link", "", { root: true });
      const result = data.userProduct
        ? await Api.shareUserProduct(
            { user_product_id: data.id },
            rootState.conf.http_options
          )
        : await Api.shareRaveReport(
            { report_id: data.id },
            rootState.conf.http_options
          );
      if (result.success) {
        const beforeQuery = result.share_link.indexOf("?");
        const domain = result.share_link.slice(0, beforeQuery);
        const queries = result.share_link.slice(beforeQuery);
        const transUrl = `${domain}#/${queries}`;
        // console.log("domain, queries, transUrl: ", domain, queries, transUrl);
        commit("conf/set_share_link", transUrl, { root: true });
        commit("conf/set_share_dialog", true, { root: true });
        commit("conf/call_share_dialog", "", { root: true });
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  utcTimeZoneForrmatter({ commit }, tz) {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: tz,
      timeZoneName: "short"
    };
    const formatter = new Intl.DateTimeFormat("ru-RU", options);
    const startingDate = new Date();
    const dateInNewTimezone = formatter.format(startingDate);
    // console.log("timezone is: ", dateInNewTimezone.slice(22));
    commit("update_transit_utc", dateInNewTimezone.slice(22));
  },
  async getInflictionByHash({ rootState, commit, dispatch, state }, hash) {
    try {
      const result = await Api.getSharedInflictionReport(
        hash,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_transit_day", {
          day: result.report.birthday2.slice(8, 10)
        });
        commit(
          "update_transit_month",
          state.dateTime.months[+result.report.birthday2.slice(5, 7) - 1]
        );
        commit("update_transit_year", {
          year: result.report.birthday2.slice(0, 4)
        });
        commit("update_transit_hour", {
          hour: result.report.birthday2.slice(11, 13)
        });
        commit("update_transit_minute", {
          minute: result.report.birthday2.slice(14)
        });
        dispatch("utcTimeZoneForrmatter", result.report.city.timezone);
        commit("update_transit_lc", [result.report.city]);
        commit("update_chart_bodygraph_chart_transit", result.report.chart);
        commit("update_chart_bodygraph_transit", result.report.transit);
        commit("update_transit_city_id", result.report.city.id);
        commit("update_transit_report_id", result.report.id);
        dispatch("removeUserProduct");
        return { success: true, report: result.report };
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async createTransitReport({ commit, dispatch, rootState }, data) {
    try {
      const result = await Api.createTransit(data, rootState.conf.http_options);
      if (result.success) {
        commit("update_chart_bodygraph_transit", result.report.transit);
        commit("update_transit_city_id", result.report.city.id);
        commit("update_transit_report_id", result.report.id);
        dispatch("utcTimeZoneForrmatter", result.report.city.timezone);
        dispatch("removeUserProduct");
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getReturnByHash({ rootState, commit, dispatch }, hash) {
    try {
      const result = await Api.getSharedReturnReport(
        hash,
        rootState.conf.http_options
      );
      if (result.success) {
        commit("update_chart_bodygraph_return", result.report);
        commit("update_return_hash", result.report.report_hash);
        dispatch("removeUserProduct");
        return { success: true, report: result.report };
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getSocialPosts({ rootState, state, commit, dispatch }, data) {
    try {
      // console.log("getSocialPosts: ", data);
      const params = data.options ? data.options.page : "";
      const result = await Api.getSocialPosts(
        params,
        data.id,
        rootState.conf.http_options
      );
      if (result.success) {
        // commit("set_posts", result.posts);
        if (state.User.community.posts_options) {
          if ("push" in state.User.community.posts_options) {
            const oldArr = state.User.community.posts;
            const concatArr = oldArr.concat(result.posts);
            state.User.community.posts = concatArr;
          } else {
            state.User.community.posts = result.posts;
            // console.log("Finaly, getSocialPosts:", state.User.community.posts);
          }
        } else {
          state.User.community.posts = result.posts;
        }
        state.User.community.loading_posts = false;
        commit("set_total_posts_pages", result._meta.pageCount);
        if (data.options) {
          // if (data.options.id) commit("set_posts", data.options.id);
          if (data.options.page) commit("set_current_page", data.options.page);
        }
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getSocialFavPosts({ rootState, state, commit, dispatch }, data) {
    try {
      const page = data.options ? data.options.page : "1";
      const result = await Api.getFavPosts(
        data.user_id,
        page,
        rootState.conf.http_options
      );
      if (result.success) {
        // commit("set_posts", result.posts);
        state.User.community.posts = result.posts;
        state.User.community.loading_posts = false;
        commit("set_total_posts_pages", result._meta.pageCount);
        if (data.options) {
          if (data.options.id) commit("set_posts", data.options.id);
          if (data.options.page) commit("set_current_page", data.options.page);
        }
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getSocialUserPosts({ rootState, state, commit, dispatch }, data) {
    try {
      const page = data.options ? data.options.page : "1";
      const result = await Api.getUserPosts(
        data.user_id,
        page,
        rootState.conf.http_options
      );
      if (result.success) {
        // commit("set_posts", result.posts);
        state.User.community.posts = result.posts;
        state.User.community.loading_posts = false;
        commit("set_total_posts_pages", result._meta.pageCount);
        if (data.options) {
          //if (data.options.id) commit("set_posts", data.options.id);
          if (data.options.page) commit("set_current_page", data.options.page);
        }
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async get_posts_from_api({ state, commit, dispatch }, options) {
    // console.log(options ? options.id : '')
    // console.log(typeof options)
    if (options) {
      if ("no_loading_posts_indicator" in options) {
        commit("set_loading_posts", false);
      } else {
        commit("set_loading_posts", true);
      }
    } else {
      options = {}; // to exclude of 'undefined'
      commit("set_loading_posts", true);
    }
    const userId = options.user_id
      ? options.user_id
      : state.User.community.viewId;
    // console.log("*** Current options ***", options);
    if (state.User.community.posts_method === "myposts") {
      // console.log("*** My posts ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_my_posts");
      dispatch("getSocialUserPosts", options);
    } else if (state.User.community.posts_method === "userposts" && options) {
      // console.log("*** Other user's posts ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_user_posts", userId);
      dispatch("getSocialUserPosts", options);
    } else if (
      state.User.community.posts_method === "myfavourites" &&
      Router.history.current.path !== "/feed"
    ) {
      // console.log("*** My favourites ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_my_fav_posts");
      dispatch("getSocialFavPosts", options);
    } else if (state.User.community.posts_method === "myfavouritesSticky") {
      // console.log("*** My favourites ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_my_fav_posts");
      dispatch("getSocialFavPosts", options);
    } else if (state.User.community.posts_method === "favourites" && options) {
      // console.log("*** Favourites post other user ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_user_fav_posts", userId);
      dispatch("getSocialFavPosts", options);
    } else {
      // console.log("*** Just Posts ***");
      // commit("set_social_posts_options", options);
      // commit("call_social_posts");
      const data = { userId, options };
      dispatch("getSocialPosts", data);
    }
  },
  async getSocialPost({ rootState, commit }, id) {
    const result = await Api.getSocialPost(id, rootState.conf.http_options);
    if (result.success) {
      // console.log("GET POST: ", result);
      commit("set_post", result.post);
    }
  },
  async sharePost({ rootState, commit, dispatch }, data) {
    try {
      commit("conf/set_share_link", "", { root: true });
      const result = await Api.sharePost(data, rootState.conf.http_options);
      if (result.success) {
        commit("conf/set_share_link", result.share_link, { root: true });
        commit("conf/set_share_dialog", true, { root: true });
        commit("conf/call_share_dialog", "", { root: true });
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async getSocialProfile({ rootState, state, commit, dispatch }) {
    try {
      const result = await Api.getSocialProfile(
        {},
        rootState.conf.http_options
      );
      if (result.success) {
        if (result.social_profile.can_add_content_to_social_network) {
          commit(
            "can_add_content_to_social_network",
            result.social_profile.can_add_content_to_social_network
          );
        }
        if (result.social_profile.images.length) {
          commit("update_comm_avatar", result.social_profile.images[0].src);
          commit("update_comm_my_avatar", result.social_profile.images[0].src);
          commit("update_comm_profile_images", result.social_profile.images);
        }
        if (result.social_profile.id) {
          commit("update_user_id", result.social_profile.id);
        }
        if (result.social_profile.first_name)
          commit("update_my_name", result.social_profile.first_name);
        if (result.social_profile.chart_type)
          commit(
            "set_comm_my_chart_type",
            result.social_profile.chart_type.title
          );
        if (result.social_profile.chart_profile)
          commit(
            "set_comm_my_chart_profile",
            result.social_profile.chart_profile.id
          );
        if (result.social_profile.slogan)
          commit("update_comm_my_slogan", result.social_profile.slogan);
        if (result.social_profile.about)
          commit("update_comm_my_about", result.social_profile.about);
        if (result.social_profile.hd_level) {
          const hdLevel = result.social_profile.hd_level;
          commit("update_comm_my_hd_level", hdLevel);
        }
        if (result.social_profile.why_am_i_here)
          commit(
            "update_comm_my_why_am_i_here",
            result.social_profile.why_am_i_here
          );
        if (result.social_profile.age >= 0)
          commit("update_user_my_age", result.social_profile.age.toString());
        if (result.social_profile.birthday) {
          commit("update_comm_my_day", {
            day: result.social_profile.birthday.slice(8, 10)
          });
          const months = state.dateTime.months;
          commit(
            "update_comm_my_month",
            months[result.social_profile.birthday.slice(5, 7) - 1]
          );
          commit("update_comm_my_year", {
            year: result.social_profile.birthday.slice(0, 4)
          });
          commit("update_comm_my_hour", {
            hour: result.social_profile.birthday.slice(11, 13)
          });
          commit("update_comm_my_minute", {
            minute: result.social_profile.birthday.slice(14, 16)
          });
        }
        if (result.social_profile.city_of_birth) {
          commit(
            "update_comm_my_city_id",
            result.social_profile.city_of_birth.id
          );
          commit(
            "update_comm_my_city_name",
            result.social_profile.city_of_birth.title
          );
          commit(
            "update_comm_my_last_cities",
            result.social_profile.city_of_birth
          );
        }
        if (result.social_profile.city) {
          commit("update_comm_my_city2_id", result.social_profile.city.id);
          commit("update_comm_my_city2_name", result.social_profile.city.title);
          commit("update_comm_my_last_cities2", result.social_profile.city);
        }
        if (result.social_profile.followers_count)
          commit("set_comm_followers", result.social_profile.followers_count);
        if (result.social_profile.following_count)
          commit("set_comm_following", result.social_profile.following_count);
        if (result.social_profile.height)
          commit("set_comm_my_height", result.social_profile.height.toString());
        if (result.social_profile.weight)
          commit("set_comm_my_weight", result.social_profile.weight.toString());
        if (result.social_profile.targets) {
          const targetSelect = state.User.community.target_select;
          let targets = [];
          result.social_profile.targets.forEach((value) => {
            targetSelect.forEach((item) => {
              if (+value === +item.value) {
                targets.push(item);
              }
            });
            commit("set_user_my_targets", targets);
          });
        }
        if (result.social_profile.social_network_product_url) {
          commit(
            "set_promo_code_url",
            result.social_profile.social_network_product_url
          );
        } else {
          commit("set_promo_code_url", "");
        }
        if (result.social_profile.sex > 0) {
          const sexSelect = state.User.community.my_profile.sexselect;
          sexSelect.forEach((item) => {
            if (+result.social_profile.sex === +item.value) {
              commit("set_user_my_sex", item);
            }
          });
        } else {
          commit("set_user_my_sex", {
            text: "Мужской",
            value: "1"
          });
        }
        if (result.social_profile.marital_status) {
          const maritalStatuses = state.User.community.marital_statuses;
          maritalStatuses.forEach((item) => {
            if (+result.social_profile.marital_status === +item.value) {
              commit("set_user_my_marital_status", item);
            }
          });
        } else {
          commit("set_user_my_marital_status", null);
        }
        if (result.social_profile.children) {
          let children = [];
          const childrenSelect = state.User.community.children_select;
          result.social_profile.children.forEach((value) => {
            childrenSelect.forEach((item) => {
              if (+value === +item.value) {
                children.push(item);
              }
            });
          });
          commit("set_user_my_children", children);
        }
        if (result.social_profile.visited_countries) {
          commit(
            "set_user_my_visited_countries",
            result.social_profile.visited_countries
          );
        } else {
          commit("set_user_my_visited_countries", "");
        }
        if (result.social_profile.i_understand) {
          commit("set_user_i_understand", result.social_profile.i_understand);
        } else {
          commit("set_user_i_understand", "");
        }
        if (result.social_profile.guests_count) {
          commit("set_guests_count", result.social_profile.guests_count);
        }
        return true;
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  async updateProfile({ rootState, commit, state, dispatch }) {
    try {
      const result = await Api.getProfile({}, rootState.conf.http_options);
      if (result.success) {
        state.Profile = result.profile;
        if (state.User.isLogged) {
          commit(
            "update_full_tooltips_count",
            result.profile.full_tooltips_count
          );
          commit(
            "update_full_comp_tooltips_count",
            result.full_composite_tooltips_count
          );
        }
        if (result.profile.reference_code) {
          commit("update_user_ref", result.profile.reference_code);
        }
        if (result.profile.is_admin) {
          commit("detect_admin", result.profile.is_admin);
        }
        if (result.profile.is_subscriber) {
          commit("email_notifications", true);
        }
        if (result.profile.new_messages_count) {
          commit(
            "reports_new_messages_count",
            result.profile.new_messages_count
          );
        }
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  updateProfileFromStore({ commit, state, dispatch }) {
    try {
      if (state.User.name) {
        commit("update_name", state.User.name);
      }
      if (state.User.isLogged) {
        commit("update_full_tooltips_count", state.Profile.full_tooltips_count);
        commit(
          "update_full_comp_tooltips_count",
          state.Profile.full_composite_tooltips_count
        );
      }
      if (state.User.reference_code) {
        commit("update_user_ref", state.User.reference_code);
      }
      /* if (state.User.profile.pro_reports !== null) {
        commit('UPDATE_IS_BUYED_PRO_TOOLTIPS', true);
      } else {
        commit('UPDATE_IS_BUYED_PRO_TOOLTIPS', false);
      } */
      if (state.User.is_admin) {
        commit("detect_admin", state.User.is_admin);
      }
      if (state.User.new_messages_count) {
        // console.log("It this!");
        commit("reports_new_messages_count", state.User.new_messages_count);
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  }
};

// mutations
const mutations = {
  have_is_one_unused_user_products(state, boolean) {
    state.User.haveIsOneUnusedUserProducts = boolean;
  },
  have_is_used_user_products(state, boolean) {
    state.User.haveIsUsedUserProducts = boolean;
  },
  have_is_used_user_composites(state, boolean) {
    state.User.haveIsUsedUserComposites = boolean;
  },
  have_is_used_pro_reports(state, boolean) {
    state.User.haveIsUsedProReports = boolean;
  },
  have_is_used_child_products(state, boolean) {
    state.User.haveIsUsedChildProducts = boolean;
  },
  have_is_used_career_product(state, boolean) {
    state.User.haveIsUsedCareerProducts = boolean;
  },
  login_success(state, v) {
    // console.log("isLogged: ", v);
    state.User.isLogged = v;
  },
  set_books(state, arr) {
    state.Books = arr;
  },
  set_products(state, arr) {
    state.Products = arr;
  },
  set_profile(state, v) {
    state.Profile = v;
  },
  normalize_profile(state) {
    state.Profile = {
      full_tooltips_count: 0,
      full_composite_tooltips_count: 0,
      is_full_tooltips_usage_enabled: false
    };
  },
  update_name(state, name) {
    state.User.name = name;
  },
  update_another_name(state, name) {
    state.User.another_name = name;
  },
  update_chart_bodygraph_name(state, v) {
    state.User.bodyGraphName = v;
  },
  update_chart_bodygraph_age(state, v) {
    state.User.bodyGraphAge = v;
  },
  update_chart_bodygraph_city(state, v) {
    state.User.bodyGraphCity = v;
  },
  update_email(state, email) {
    state.User.email = email;
  },
  update_token(state, token) {
    state.User.access_token = token;
  },
  update_justclick(state, v) {
    state.User.is_subscribed_to_justclick = v;
  },
  update_registered(state, v) {
    state.User.registered = v;
  },
  update_user_id(state, v) {
    state.User.id = v;
  },
  update_user_total_income(state, num) {
    state.User.total_income = num;
  },
  update_user_current_balance(state, num) {
    state.User.current_balance = num;
  },
  update_user_ref(state, val) {
    state.User.reference_code = val;
  },
  update_user_ext_ref(state, val) {
    state.User.ext_reference_code = val;
  },
  update_user_referals_count(state, val) {
    state.User.referrals_count = val;
  },
  update_year(state, year) {
    state.User.year = year;
  },
  update_month(state, month) {
    state.User.month = month;
  },
  update_day(state, day) {
    state.User.day = day;
  },
  update_hour(state, hour) {
    state.User.hour = hour;
  },
  update_minute(state, minute) {
    state.User.minute = minute;
  },
  update_city(state, name) {
    state.User.city = name;
  },
  update_city_id(state, id) {
    state.User.cityid = id;
  },
  update_transit_city_id(state, id) {
    state.Transit.cityid = id;
  },
  update_last_cities(state, arr) {
    state.User.lastCities = arr;
  },
  update_before_posting(state, val) {
    state.User.community.beforePosting = val;
  },
  update_before_posting_dialog(state, val) {
    state.User.community.beforePostingDialog = val;
  },
  update_was_open_add_post(state, val) {
    state.User.was_open_add_post = val;
  },
  update_was_switched(state, val) {
    state.User.was_switched = val;
  },
  update_before_editing_profile(state, val) {
    state.User.community.beforeEditingProfile = val;
  },
  update_before_editing_profile_dialog(state, val) {
    state.User.community.beforeEditingProfileDialog = val;
  },
  update_before_editing_user_images(state, val) {
    state.User.community.beforeEditingUserImages = val;
  },
  update_before_editing_user_images_dialog(state, val) {
    state.User.community.beforeEditingUserImagesDialog = val;
  },
  update_before_people_searching(state, val) {
    state.User.community.beforeSelector = val;
  },
  update_before_people_searching_dialog(state, val) {
    state.User.community.beforeSelectorDialog = val;
  },
  update_chart_bodygraph(state, obj) {
    state.User.chartBodygraph = obj;
  },
  update_chart_bodygraph_birthtime(state, v) {
    state.User.chartBodygraph.birthtime = v;
  },
  update_tooltips(state, obj) {
    state.User.toolTips = obj;
  },
  update_report_id(state, id) {
    state.User.reportid = id;
  },
  update_report_name_rod(state, name) {
    state.User.reportNameRod = name;
  },
  update_report_hash(state, hash) {
    state.User.report_hash = hash;
  },
  is_user_product_hash(state, hash) {
    state.User.is_user_product_hash = !!hash;
  },
  update_composite_hash(state, hash) {
    state.Composite.composite_hash = hash;
  },
  update_transit_report_id(state, id) {
    state.Transit.report_id = id;
  },
  get_transit_by_hash(state, hash) {
    state.ext_transit_hash = hash;
  },
  update_infliction_hash(state, hash) {
    state.Transit.infliction_hash = hash;
  },
  get_infliction_by_hash(state, hash) {
    state.ext_infliction_hash = hash;
  },
  update_transit_hash(state, hash) {
    state.Transit.transit_hash = hash;
  },
  update_return_report_id(state, id) {
    state.Return.report_id = id;
  },
  get_return_by_hash(state, hash) {
    state.ext_return_hash = hash;
  },
  update_return_hash(state, hash) {
    state.Return.return_hash = hash;
  },
  update_is_stored(state, num) {
    state.User.is_stored = num;
  },
  is_pro_tooltips(state, boolean) {
    state.User.isProTooltips = boolean;
  },
  update_user_referals_ctr(state, val) {
    state.User.referrals_ctr = val;
  },
  set_reports_history(state, arr) {
    state.User.reportsHistory.reports = arr;
  },
  set_reports_history_count(state, v) {
    state.User.reportsHistory.totalCount = v;
  },
  set_reports_history_page_count(state, v) {
    state.User.reportsHistory.pageCount = v;
  },
  set_is_harmonious_gates(state, v) {
    state.User.isHarmoniousGates = v;
  },
  set_is_love_gates(state, v) {
    state.User.isLoveGates = v;
  },
  set_is_fears_gates(state, v) {
    state.User.isFearsGates = v;
  },
  set_is_malancholy_gates(state, v) {
    state.User.isMelancholyGates = v;
  },
  set_is_dream_gates(state, v) {
    state.User.isDreamGates = v;
  },
  update_report_id_pro(state, id) {
    state.User.reportidPro = id;
  },
  update_user_product_id(state, id) {
    state.User.user_products_id = id;
  },
  update_reports_history_dialog(state, v) {
    state.User.reportsHistory.dialogStatus = v;
  },
  update_pin(state, val) {
    state.User.pin_request = val;
  },
  set_composites_history(state, arr) {
    state.User.compositesHistory.reports = arr;
  },
  set_composites_history_count(state, v) {
    state.User.compositesHistory.totalCount = v;
  },
  set_composites_history_page_count(state, v) {
    state.User.compositesHistory.pageCount = v;
  },
  update_composites_history_dialog(state, v) {
    state.User.compositesHistory.dialogStatus = v;
  },
  update_full_tooltips_count(state, v) {
    state.Profile.full_tooltips_count = v;
  },
  update_full_comp_tooltips_count(state, v) {
    state.User.full_composite_tooltips_count = v;
  },
  update_is_full_tooltips_usage_enable(state, v) {
    state.Profile.is_full_tooltips_usage_enabled = v;
  },
  update_first_bg_dialog(state, v) {
    state.firstBgDialog = v;
  },
  update_min(state, val) {
    state.User.min_withdrawal = val;
  },
  update_max(state, val) {
    state.User.current_balance = val;
  },
  update_slider(state, val) {
    state.User.slider = val;
  },
  update_chart_bodygraph_composite(state, obj) {
    state.Composite.chartBodygraphChart = obj.chart;
    state.Composite.chartBodygraphChart2 = obj.chart2;
    state.Composite.chartBodygraphConnection = obj.connection;
    state.Composite.report_id = obj.id;
    state.Composite.composite_hash = obj.report_hash;
    state.Composite.is_stored = obj.is_stored;
    state.Composite.chartBodygraphChartName = obj.first_name;
    state.Composite.chartBodygraphChartName2 = obj.first_name2;
  },
  set_chart_bodygraph_composite(state, v) {
    state.Composite.chartBodygraphChart = v;
  },
  set_chart_bodygraph_composite2(state, v) {
    state.Composite.chartBodygraphChart2 = v;
  },
  set_chart_bodygraph_composite_connection(state, obj) {
    state.Composite.chartBodygraphConnection = obj;
  },
  update_composite_is_stored(state, num) {
    state.Composite.is_stored = num;
  },
  update_composite_report_id(state, id) {
    state.Composite.report_id = id;
  },
  update_composite_report_hash(state, hash) {
    state.Composite.composite_hash = hash;
  },
  get_body_graph(state, m) {
    state.getBodygraph = m;
  },
  get_body_graph_just_click(state, m) {
    state.getBodygraph = m;
  },
  clear_open_tooltips(state, val) {
    state.openTooltips = val;
  },
  clear_gate_in_center_hover(state) {
    state.gateInCenterHover = [];
  },
  set_is_planet_per(state, obj) {
    state.isPlanetPer[obj.planet] = obj.num;
  },
  set_is_planet_des(state, obj) {
    state.isPlanetDes[obj.planet] = obj.num;
  },
  set_is_planet_per2(state, obj) {
    state.isPlanetPer2[obj.planet] = obj.num;
  },
  set_is_planet_des2(state, obj) {
    state.isPlanetDes2[obj.planet] = obj.num;
  },
  set_is_planet_trs(state, obj) {
    state.isPlanetTrs[obj.planet] = obj.num;
  },
  set_progress_status(state, v) {
    state.progresStatus = v;
  },
  push_to_gate_in_center_hover(state, gate) {
    state.gateInCenterHover.push(gate);
  },
  update_path_history(state) {
    state.User.pathHistory = state.User.pathHistory + 1;
  },
  update_reg_go_text_before_btn_1(state, val) {
    state.User.RegGoTextBeforeBtn = val;
  },
  update_btn_tooltip_name_1(state, val) {
    state.User.btnTooltipName = val;
  },
  update_reg_go_text_before_btn_2(state, val) {
    state.User.RegGoTextBeforeBtnComposite = val;
  },
  update_btn_tooltip_name_2(state, val) {
    state.User.btnTooltipNameComposite = val;
  },
  UPDATE_IS_VISIBLE_TOOLTIP_BTN(state, val) {
    state.User.isVisibleTooltipBtn = val;
  },
  UPDATE_USER_TOOLTIPS_OFFER_BUY(state, val) {
    state.User.isTooltipsOfferbuy = val;
  },
  UPDATE_USER_TOOLTIPS_OFFER_BUY_BTN_OK(state, val) {
    state.User.isTooltipsOfferbuyBtnOk = val;
  },
  update_transit_day(state, day) {
    state.Transit.day = day;
  },
  UPDATE_MONTH(state, month) {
    state.User.month = month;
  },
  update_transit_month(state, month) {
    state.Transit.month = month;
  },
  update_transit_year(state, year) {
    state.Transit.year = year;
  },
  update_transit_hour(state, hour) {
    state.Transit.hour = hour;
  },
  update_transit_minute(state, minute) {
    state.Transit.minute = minute;
  },
  update_transit_utc(state, utc) {
    state.Transit.utc = utc;
  },
  update_transit_lc(state, arr) {
    state.Transit.lastCities = arr;
  },
  update_chart_bodygraph_transit(state, obj) {
    state.Transit.chartBodygraphTransit = obj;
  },
  UPDATE_TOOLTIPS_TRANSIT(state, obj) {
    state.Transit.toolTips = obj;
  },
  UPDATE_TR_TOOLTIPS_BUTTON_TYPE(/* state, tr */) {
    // state.ConfApp.tr_tooltip_button_type = tr;
  },
  update_chart_bodygraph_chart_transit(state, obj) {
    state.Transit.chartBodygraphChart = obj;
  },
  set_ext_transit_hash(state, v) {
    state.ext_transit_hash = v;
  },
  set_ext_infliction_hash(state, v) {
    state.ext_infliction_hash = v;
  },
  set_ext_return_by_hash(state, hash) {
    state.ext_return_hash = hash;
  },
  update_chart_bodygraph_return(state, obj) {
    state.Return.chartBodygraphChart = obj.chart;
    state.Return.chartBodygraphChart2 = obj.chart2;
    state.Return.chartBodygraphConnection = obj.connection;
    state.Return.report_id = obj.id;
  },
  // Social
  reports_new_messages_count(state, n) {
    state.User.new_messages_count = n;
  },
  set_posts_method(state, method) {
    state.User.community.posts_method = method;
  },
  set_posts(state, posts) {
    if (state.User.community.posts_options) {
      if ("push" in state.User.community.posts_options) {
        const oldArr = state.User.community.posts;
        const concatArr = oldArr.concat(posts);
        state.User.community.posts = concatArr;
      } else {
        state.User.community.posts = posts;
        // console.log("Finaly, set_posts:", state.User.community.posts_options);
      }
    } else {
      state.User.community.posts = posts;
    }
    state.User.community.loading_posts = false;
  },
  set_current_page(state, page) {
    state.User.community.page = page;
  },
  set_post(state, item) {
    state.User.community.post = item;
  },
  set_post_comments(state, comments) {
    state.User.community.comments = comments;
  },
  set_total_posts_pages(state, val) {
    state.User.community.total_post_pages = val;
  },
  set_loading_posts(state, val) {
    state.User.community.loading_posts = val;
  },
  set_social_posts_options(state, obj) {
    state.User.community.posts_options = obj;
    console.log(
      "set_social_posts_options: ",
      state.User.community.posts_options
    );
  },
  set_search_result(state, arr) {
    arr.forEach((user) => {
      if (user.bodygraph && user.images.length) {
        user.images.splice(1, 0, {
          id: "bg999",
          src: user.bodygraph
        });
      }
      if (user.bodygraph && !user.images.length) {
        user.images.push({
          id: 1,
          src:
            "https://static.human-design.space/widget/images/social/socialprofile-preview.png"
        });
        user.images.push({
          id: 2,
          src: user.bodygraph
        });
      }
      if (!user.bodygraph && !user.images.length) {
        user.images.push({
          id: 1,
          src:
            "https://static.human-design.space/widget/images/social/socialprofile-preview.png"
        });
      }
      // Add fake image to array end
      user.images.push({
        id: user.images.length + 2,
        name: "fake image",
        src: "https://static.human-design.space/widget/images/r1x1.png"
      });
    });
    state.User.community.searchResult = arr;
  },
  clear_search_result(state) {
    state.User.community.searchResult = [];
  },
  set_search_meta_result(state, obj) {
    state.User.community.searchMetaResult = obj;
  },
  set_search_next_page(state, num) {
    state.User.community.searchNextPage = num;
  },
  call_update_like() {
    // call_update_like
  },
  call_is_relative_like_dialog(state, user) {
    state.User.community.selected_user = user;
  },
  call_update_bookmark() {
    // call_update_bookmark
  },
  call_update_comments() {
    // call_update_comments
  },
  call_clear_active_user() {
    // Call to clear active user
  },
  call_close_search_people_result_dialog() {
    // call_close_search_people_result_dialog
  },
  call_search_people() {
    // call_search_people
  },
  call_social_posts() {},
  call_social_my_posts() {},
  call_social_my_fav_posts() {},
  call_re_render_mutual_interest() {},
  call_clear_chat_edit_message() {},
  call_social_post() {},
  call_social_user_posts(state, id) {
    state.User.community.getPostsUserId = +id;
  },
  call_social_user_fav_posts(state, id) {
    state.User.community.getPostsUserId = +id;
  },
  detect_admin(state, v) {
    state.User.is_admin = v;
  },
  email_notifications(state, v) {
    state.User.is_subscriber = v;
  },
  set_post_spam_id(state, id) {
    state.User.community.post_spam_id = id;
  },
  set_post_spam_dialog(state, val) {
    state.User.community.post_spam_dialog = val;
  },
  call_post_spam_dialog() {},
  call_comments_counter_plus() {},
  call_comments_counter_minus() {},
  call_block_post(state, obj) {
    state.User.community.block_post_id = obj.id;
    state.User.community.block_post_status = obj.status;
  },
  call_delete_post(state, id) {
    state.User.community.delete_post_id = id;
  },
  call_edit_post(state, idx) {
    state.User.community.edit_post_idx = idx;
  },
  call_social_profile_check_modal() {},
  can_add_content_to_social_network(state, v) {
    state.User.community.my_profile.can_add_content_to_social_network = v;
  },
  set_partner_info_text(state, text) {
    state.Profile.partner_info_text = text;
  },
  call_partner_dialog() {},
  update_my_name(state, name) {
    state.User.community.my_profile.name = name;
  },
  update_comm_profile_images(state, images) {
    state.User.community.my_profile.images = images;
  },
  call_user_view_dialog(state, id) {
    state.User.community.viewId = +id;
  },
  update_user_no_interest_in_soc_profile(state, val) {
    state.User.no_interesting_in_social_profile = val;
  },
  update_social_steppers(state, val) {
    state.User.community.socialSteppers = val;
  },
  set_user_selected(state, user) {
    state.User.community.selected_user = user;
  },
  set_comm_chart_type(state, type) {
    state.User.community.chart_type = type;
  },
  set_comm_my_chart_type(state, type) {
    state.User.community.my_profile.chart_type = type;
  },
  set_comm_chart_profile(state, profile) {
    state.User.community.chart_profile = profile;
  },
  set_comm_my_chart_profile(state, profile) {
    state.User.community.my_profile.chart_profile = profile;
  },
  update_comm_avatar(state, avatar) {
    state.User.community.avatar = avatar;
  },
  update_comm_my_avatar(state, avatar) {
    state.User.community.my_profile.avatar = avatar;
  },
  update_comm_day(state, day) {
    state.User.community.day = day;
  },
  update_comm_my_day(state, day) {
    state.User.community.my_profile.day = day;
  },
  update_comm_month(state, month) {
    state.User.community.month = month;
  },
  update_comm_my_month(state, month) {
    state.User.community.my_profile.month = month;
  },
  update_comm_year(state, year) {
    state.User.community.year = year;
  },
  update_comm_my_year(state, year) {
    state.User.community.my_profile.year = year;
  },
  update_comm_hour(state, hour) {
    state.User.community.hour = hour;
  },
  update_comm_my_hour(state, hour) {
    state.User.community.my_profile.hour = hour;
  },
  update_comm_minute(state, minute) {
    state.User.community.minute = minute;
  },
  update_comm_my_minute(state, minute) {
    state.User.community.my_profile.minute = minute;
  },
  update_comm_city_id(state, id) {
    state.User.community.cityid = id;
  },
  update_comm_my_city_id(state, id) {
    state.User.community.my_profile.cityid = id;
  },
  update_comm_city2_id(state, id) {
    state.User.community.city2id = id;
  },
  update_comm_my_city2_id(state, id) {
    state.User.community.my_profile.city2id = id;
  },
  update_comm_city_name(state, val) {
    state.User.community.city = val;
  },
  update_comm_my_city_name(state, val) {
    state.User.community.my_profile.city = val;
  },
  update_comm_my_city2_name(state, val) {
    state.User.community.my_profile.city2 = val;
  },
  update_comm_last_cities(state, obj) {
    state.User.community.cities = [obj];
  },
  update_comm_my_last_cities(state, obj) {
    state.User.community.my_profile.cities = [obj];
  },
  update_comm_last_cities2(state, obj) {
    state.User.community.cities2 = [obj];
  },
  update_comm_my_last_cities2(state, obj) {
    state.User.community.my_profile.cities2 = [obj];
  },
  update_comm_slogan(state, val) {
    state.User.community.slogan = val;
  },
  update_comm_my_slogan(state, val) {
    state.User.community.my_profile.slogan = val;
  },
  update_comm_hd_level(state, val) {
    state.User.community.hd_level = val;
  },
  update_comm_my_hd_level(state, val) {
    state.User.community.my_profile.hd_level = val;
  },
  update_comm_why_am_i_here(state, val) {
    state.User.community.why_am_i_here = val;
  },
  update_comm_my_why_am_i_here(state, val) {
    state.User.community.my_profile.why_am_i_here = val;
  },
  update_comm_about(state, val) {
    state.User.community.about = val;
  },
  update_comm_my_about(state, val) {
    state.User.community.my_profile.about = val;
  },
  update_comm_images_validation(state, val) {
    state.User.community.isImagesValidation = val;
  },
  set_comm_followers(state, val) {
    state.User.community.followers_count = val;
  },
  set_comm_following(state, val) {
    state.User.community.following_count = val;
  },
  set_comm_height(state, val) {
    state.User.community.height = val;
  },
  set_comm_my_height(state, val) {
    state.User.community.my_profile.height = val;
  },
  set_comm_weight(state, val) {
    state.User.community.weight = val;
  },
  set_comm_my_weight(state, val) {
    state.User.community.my_profile.weight = val;
  },
  set_user_targets(state, val) {
    state.User.community.targets = val;
  },
  set_user_my_targets(state, val) {
    state.User.community.my_profile.targets = val;
  },
  set_user_targets_select(state, val) {
    state.User.community.target_select = val;
  },
  set_user_raves_count(state, val) {
    state.User.raves_count = val;
  },
  update_user_age(state, age) {
    state.User.age = age;
  },
  update_user_my_age(state, age) {
    state.User.community.my_profile.age = age;
  },
  set_user_marital_statuses(state, val) {
    state.User.community.marital_statuses = val;
  },
  set_user_marital_status(state, val) {
    state.User.community.marital_status = val;
  },
  set_user_my_marital_status(state, val) {
    state.User.community.my_profile.marital_status = val;
  },
  set_user_target_select(state, val) {
    state.User.community.target_select = val;
  },
  set_user_children_select(state, val) {
    state.User.community.children_select = val;
  },
  set_user_children(state, val) {
    state.User.community.children = val;
  },
  set_user_my_children(state, val) {
    state.User.community.my_profile.children = val;
  },
  set_user_visited_countries(state, val) {
    state.User.community.visited_countries = val;
  },
  set_user_my_visited_countries(state, val) {
    state.User.community.my_profile.visited_countries = val;
  },
  set_user_i_understand(state, val) {
    state.User.community.my_profile.i_understand = val;
  },
  set_guests_count(state, val) {
    state.User.community.my_profile.guests_count = val;
  },
  confirm_email_dialog() {},
  confirm_email_dialog_close() {},
  call_followers_update() {},
  call_subscriptions_update() {},
  call_update_blocked_users_chats() {},
  call_update_profile(state, val) {
    state.User.profile = val;
  },
  set_promo_code_url(state, url) {
    state.User.community.promoCodeUrl = url;
  },
  set_user_sex(state, val) {
    state.User.community.sex = val;
  },
  set_user_my_sex(state, val) {
    state.User.community.my_profile.sex = val;
  },
  set_user_sexselect(state, val) {
    state.User.community.sexselect = val;
  },
  set_user_my_sexselect(state, val) {
    state.User.community.my_profile.sexselect = val;
  },
  set_user_posts_categories(state, categories) {
    state.User.community.categories = categories;
  },
  set_user_city_posts_only(state, val) {
    state.User.community.show_my_city_posts_only = val;
  },
  set_search_vocabulary_chart_types(state, arr) {
    state.User.community.searchVocabulary.chart_types = arr;
  },
  set_search_vocabulary_chart_authorities(state, arr) {
    state.User.community.searchVocabulary.chart_authorities = arr;
  },
  set_search_vocabulary_chart_centers(state, arr) {
    state.User.community.searchVocabulary.chart_centers = arr;
  },
  set_search_vocabulary_chart_channels(state, arr) {
    state.User.community.searchVocabulary.chart_channels = arr;
  },
  set_search_vocabulary_chart_definitions(state, arr) {
    state.User.community.searchVocabulary.chart_definitions = arr;
  },
  set_search_vocabulary_chart_gates(state, arr) {
    state.User.community.searchVocabulary.chart_gates = arr;
  },
  set_search_vocabulary_chart_profiles(state, arr) {
    state.User.community.searchVocabulary.chart_profiles = arr;
  },
  set_search_vocabulary_chart_quarters(state, arr) {
    state.User.community.searchVocabulary.chart_quarters = arr;
  },
  set_search_vocabulary_chart_angles(state, arr) {
    state.User.community.searchVocabulary.chart_angles = arr;
  },
  set_is_conversation_called_from_profile(state, v) {
    state.User.community.isConversationCalledFromProfile = v;
  },
  set_is_conversation_called_from_chats(state, v) {
    state.User.community.isConversationCalledFromChats = v;
  },
  set_online_user_ids(state, arr) {
    state.User.community.chats.onlineUserIDs = arr;
  },
  add_online_user_ids(state, id) {
    const arr = state.User.community.chats.onlineUserIDs;
    if (!arr.includes(id)) {
      arr.push(id);
    }
    // console.log('onlineUserIDs: ', arr)
  },
  delete_online_user_ids(state, id) {
    const arr = state.User.community.chats.onlineUserIDs;
    // console.log("arr: ", arr);
    arr.forEach((arrId, i) => {
      if (arrId === id) {
        arr.splice(i, 1);
      }
    });
    // console.log("onlineUserIDs: ", arr);
  },
  call_conversation() {},
  call_conversation_outside(state, user) {
    state.User.community.selected_user = user;
  },
  call_scroll_chat_to_footer() {},
  call_recount_total_new_messages_count() {},
  call_dialog_add_post() {},
  call_dialog_edit_profile(state, v) {
    if (v) {
      state.User.community.edit_social_profile = v;
    }
  },
  call_dialog_edit_profile_off(state, v) {
    state.User.community.edit_social_profile = v;
  },
  call_dialog_edit_user_images() {},
  resort_chat_users() {},
  call_user_is_writing(state, id) {
    state.User.community.chats.isWritingUserId = id;
  },
  set_users_from_api(state, arr) {
    state.User.community.chats.ApiUsers = arr;
  },
  unshift_new_user_to_users_from_api(state, obj) {
    state.User.community.chats.ApiUsers.unshift(obj);
  },
  set_selected_user_messages(state, arr) {
    state.User.community.chats.selectedUserMessages = arr;
  },
  push_to_selected_user_messages(state, obj) {
    state.User.community.chats.selectedUserMessages.push(obj);
  },
  set_has_new_messages(state, val) {
    state.User.community.newMessagesCount = val;
  },
  set_chat_users(state, arr) {
    state.User.community.chatUsers = arr;
  },
  new_list_user_unshift(state, obj) {
    state.User.community.chats.newListUser = obj;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
