import { HTTP } from "@/plugins/axios";
// import Store from "@/store/index";
// const options = Store.state.http_options;

export default class Api {
  static verify(data, options) {
    return HTTP.post("auth/verify", data, options).then(
      (response) => response.data
    );
  }
  static login(data, options) {
    return HTTP.post("auth/login", data, options).then(
      (response) => response.data
    );
  }
  static register(data, options) {
    return HTTP.post("auth/signup", data, options).then(
      (response) => response.data
    );
  }
  static logout(data, options) {
    return HTTP.post("auth/logout", data, options).then(
      (response) => response.data
    );
  }
  static requestPasswordResetPin(data, options) {
    return HTTP.post("/auth/request-password-reset-pin", data, options).then(
      (response) => response.data
    );
  }
  static validatePasswordResetPin(data, options) {
    return HTTP.post("/auth/validate-password-reset-pin", data, options).then(
      (response) => response.data
    );
  }
  static validateResetPassword(data, options) {
    return HTTP.post("/auth/reset-password", data, options).then(
      (response) => response.data
    );
  }
  static searchSities(data, options) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("cities/get-cities" + queries, options).then(
      (response) => response.data
    );
  }
  static createReport(data, options) {
    return HTTP.post("raves/create-rave-report", data, options).then(
      (response) => response.data
    );
  }
  static createTransit(data, options) {
    return HTTP.post("transits/create-transit-report", data, options).then(
      (response) => response.data
    );
  }
  static createInfliction(data, options) {
    return HTTP.post(
      "inflictions/create-infliction-report",
      data,
      options
    ).then((response) => response.data);
  }
  static getProfile(data, options) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("auth/get-profile" + queries, options).then(
      (response) => response.data
    );
  }
  static getConfirmEmail(data, options) {
    const queries = data ? "?email_hash=" + data : "";
    return HTTP.get("auth/confirm-email" + queries, options).then(
      (response) => response.data
    );
  }
  static getReport(id, options) {
    const queries = id ? "?report_id=" + id : "";
    return HTTP.get("raves/get-rave-report" + queries, options).then(
      (response) => response.data
    );
  }
  static shareRaveReport(data, options) {
    return HTTP.post("raves/share-rave-report", data, options).then(
      (response) => response.data
    );
  }
  static shareTransitReport(data, options) {
    return HTTP.post("transits/share-transit-report", data, options).then(
      (response) => response.data
    );
  }
  static shareInflictionReport(data, options) {
    return HTTP.post("inflictions/share-infliction-report", data, options).then(
      (response) => response.data
    );
  }
  static getSharedTransitReport(hash, options) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "transits/get-shared-transit-report" + queries,
      options
    ).then((response) => response.data);
  }
  static getSharedInflictionReport(hash, options) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "inflictions/get-shared-infliction-report" + queries,
      options
    ).then((response) => response.data);
  }
  static getSharedReturnReport(hash, options) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get("returns/get-shared-return-report" + queries, options).then(
      (response) => response.data
    );
  }
  static getSharedRaveReport(hash, options) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get("raves/get-shared-rave-report" + queries, options).then(
      (response) => response.data
    );
  }
  static shareUserProduct(data, options) {
    return HTTP.post("user-products/share-user-product", data, options).then(
      (response) => response.data
    );
  }
  static getSettings(data, options) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("settings/get-settings" + queries, options).then(
      (response) => response.data
    );
  }
  static createComposite(data, options) {
    return HTTP.post("composites/create-composite-report", data, options).then(
      (response) => response.data
    );
  }
  static shareCompositeReport(data, options) {
    return HTTP.post("composites/share-composite-report", data, options).then(
      (response) => response.data
    );
  }
  static storeCompositeReport(data, options) {
    return HTTP.post("composites/store-composite-report", data, options).then(
      (response) => response.data
    );
  }
  static unstoreCompositeReport(data, options) {
    return HTTP.post("composites/unstore-composite-report", data, options).then(
      (response) => response.data
    );
  }
  static createSunReport(data, options) {
    return HTTP.post("returns/create-sun-report", data, options).then(
      (response) => response.data
    );
  }
  static createSaturnReport(data, options) {
    return HTTP.post("returns/create-saturn-report", data, options).then(
      (response) => response.data
    );
  }
  static createSaturn2Report(data, options) {
    return HTTP.post("returns/create-saturn2-report", data, options).then(
      (response) => response.data
    );
  }
  static createChironReport(data, options) {
    return HTTP.post("returns/create-chiron-report", data, options).then(
      (response) => response.data
    );
  }
  static createUranusReport(data, options) {
    return HTTP.post("returns/create-uranus-report", data, options).then(
      (response) => response.data
    );
  }
  static getBooks(options) {
    return HTTP.get("books/get-books", options).then(
      (response) => response.data
    );
  }
  static downloadBook(id, token, partner) {
    return HTTP({
      url: `books/download-book?access-token=${token}&book_id=${id}&partner_id=${partner}`,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `book_${id}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }
  static searchCity(q, options) {
    const query = q ? "?q=" + q : "";
    return HTTP.get("cities/get-cities" + query, options).then(
      (response) => response.data
    );
  }
  static getProducts(options) {
    return HTTP.get("products/get-products", options).then(
      (response) => response.data
    );
  }
  static getСonsultations(options) {
    return HTTP.get("consultations/get-consultations", options).then(
      (response) => response.data
    );
  }
  static getRaveReports(page, options) {
    return HTTP.get(`raves/get-rave-reports?page=${page}`, options).then(
      (response) => response.data
    );
  }
  static createRaveReport(data, options) {
    return HTTP.post("raves/create-rave-report", data, options).then(
      (response) => response.data
    );
  }
  static storeRaveReport(data, options) {
    return HTTP.post("raves/store-rave-report", data, options).then(
      (response) => response.data
    );
  }
  static unstoreRaveReport(data, options) {
    return HTTP.post("raves/unstore-rave-report", data, options).then(
      (response) => response.data
    );
  }
  static getRaveReport(id, options) {
    const query = id ? "?report_id=" + id : "";
    return HTTP.get("raves/get-rave-report" + query, options).then(
      (response) => response.data
    );
  }
  static getReportFromUserProduct(id, options) {
    const query = id ? "?user_product_id=" + id : "";
    return HTTP.get(
      "user-products/get-report-from-user-product" + query,
      options
    ).then((response) => response.data);
  }
  static getCompositeReports(page, options) {
    return HTTP.get(
      `composites/get-composite-reports?page=${page}`,
      options
    ).then((response) => response.data);
  }
  static getCompositeReport(id, options) {
    const queries = id ? "?report_id=" + id : "";
    return HTTP.get("composites/get-composite-report" + queries, options).then(
      (response) => response.data
    );
  }
  static getSharedCompositeReport(hash, options) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "composites/get-shared-composite-report" + queries,
      options
    ).then((response) => response.data);
  }
  static appEvents(data, options) {
    return HTTP.post("app-events/track-app-event", data, options).then(
      (response) => response.data
    );
  }
  static setNotifications(data, options) {
    return HTTP.post("settings/update-settings", data, options).then(
      (response) => response.data
    );
  }
  static requestWithdrawal(data, options) {
    return HTTP.post("auth/request-withdrawal", data, options).then(
      (response) => response.data
    );
  }
  static reMove(data, options) {
    return HTTP.post("auth/delete", data, options).then(
      (response) => response.data
    );
  }
  static changePassword(data, options) {
    return HTTP.post("auth/change-password", data, options).then(
      (response) => response.data
    );
  }
  static getSocialPosts(page, id, options) {
    return HTTP.get(`posts/get-posts?page=${page}`, options).then(
      (response) => response.data
    );
  }
  static getSocialPost(id, options) {
    return HTTP.get(`posts/get-post?post_id=${id}`, options).then(
      (response) => response.data
    );
  }
  static getFavPosts(id, page, options) {
    page = page ? "&page=" + page : "";
    return HTTP.get(
      `posts/get-favourite-posts?user_id=${id}${page}`,
      options
    ).then((response) => response.data);
  }
  static getUserPosts(id, page, options) {
    page = page ? "&page=" + page : "";
    return HTTP.get(`posts/get-user-posts?user_id=${id}${page}`, options).then(
      (response) => response.data
    );
  }
  static sharePost(data, options) {
    return HTTP.post("posts/share-post", data, options).then(
      (response) => response.data
    );
  }
  static complainAboutPost(data, options) {
    return HTTP.post("posts/complain-about-post", data, options).then(
      (response) => response.data
    );
  }
  static blockPost(data, options) {
    return HTTP.post("posts/block-post", data, options).then(
      (response) => response.data
    );
  }
  static unblockPost(data, options) {
    return HTTP.post("posts/unblock-post", data, options).then(
      (response) => response.data
    );
  }
  static getSocialProfile(data, options) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("auth/get-social-profile" + queries, options).then(
      (response) => response.data
    );
  }
  static createPost(data, options) {
    return HTTP.post("posts/create-post", data, options).then(
      (response) => response.data
    );
  }
  static updatePost(data, options) {
    return HTTP.post("/posts/update-post", data, options).then(
      (response) => response.data
    );
  }
  static deletePost(data, options) {
    return HTTP.post("posts/delete-post", data, options).then(
      (response) => response.data
    );
  }
  static likePost(data, options) {
    return HTTP.post("posts/like-post", data, options).then(
      (response) => response.data
    );
  }
  static unlikePost(data, options) {
    return HTTP.post("posts/unlike-post", data, options).then(
      (response) => response.data
    );
  }
  static addPostToFavourites(data, options) {
    return HTTP.post("posts/add-post-to-favourites", data, options).then(
      (response) => response.data
    );
  }
  static removePostFromFavourites(data, options) {
    return HTTP.post("posts/remove-post-from-favourites", data, options).then(
      (response) => response.data
    );
  }
  static getComments(id, options) {
    const queries = id ? "?post_id=" + id : "";
    return HTTP.get("posts/get-comments" + queries, options).then(
      (response) => response.data
    );
  }
  static createComment(data, options) {
    return HTTP.post("posts/create-comment", data, options).then(
      (response) => response.data
    );
  }
  static updateComment(data, options) {
    return HTTP.post("posts/update-comment", data, options).then(
      (response) => response.data
    );
  }
  static complainAboutСomment(data, options) {
    return HTTP.post("comments/complain-about-comment", data, options).then(
      (response) => response.data
    );
  }
  static blockComment(data, options) {
    return HTTP.post("comments/block-comment", data, options).then(
      (response) => response.data
    );
  }
  static unblockComment(data, options) {
    return HTTP.post("comments/unblock-comment", data, options).then(
      (response) => response.data
    );
  }
  static likeСomment(data, options) {
    return HTTP.post("comments/like-comment", data, options).then(
      (response) => response.data
    );
  }
  static unlikeСomment(data, options) {
    return HTTP.post("comments/unlike-comment", data, options).then(
      (response) => response.data
    );
  }
  static getUserSocial(id, options) {
    const queries = id ? "?user_id=" + id : "";
    return HTTP.get("social-network/get-user" + queries, options).then(
      (response) => response.data
    );
  }
  static requestUserImage(data, options) {
    return HTTP.post("social-network/request-user-image", data, options).then(
      (response) => response.data
    );
  }
  static createBlockedUsers(data, options) {
    return HTTP.post("social-network/create-blocked-users", data, options).then(
      (response) => response.data
    );
  }
  static deleteBlockedUsers(data, options) {
    return HTTP.post("social-network/delete-blocked-users", data, options).then(
      (response) => response.data
    );
  }
  static getBlockedUsers(options) {
    return HTTP.get("social-network/get-blocked-users", options).then(
      (response) => response.data
    );
  }
  static getFavouriteUsers(options) {
    return HTTP.get("social-network/get-favourite-users", options).then(
      (response) => response.data
    );
  }
  static createFavouriteUsers(data, options) {
    return HTTP.post(
      "social-network/create-favourite-users",
      data,
      options
    ).then((response) => response.data);
  }
  static deleteFavouriteUsers(data, options) {
    return HTTP.post(
      "social-network/delete-favourite-users",
      data,
      options
    ).then((response) => response.data);
  }
  static getFollowers(id, options) {
    const queries = id ? "&user_id=" + id : "";
    return HTTP.get(
      "social-network/get-followers?page=1" + queries,
      options
    ).then((response) => response.data);
  }
  static getGuests(id, options) {
    const queries = id ? "?user_id=" + id : "";
    return HTTP.get("social-network/get-guests" + queries, options).then(
      (response) => response.data
    );
  }
  static followUser(data, options) {
    return HTTP.post("social-network/follow-user", data, options).then(
      (response) => response.data
    );
  }
  static unfollowUser(data, options) {
    return HTTP.post("social-network/unfollow-user", data, options).then(
      (response) => response.data
    );
  }
  static skipUser(data, options) {
    return HTTP.post("social-network/skip-user", data, options).then(
      (response) => response.data
    );
  }
  static likeUser(data, options) {
    return HTTP.post("social-network/like-user", data, options).then(
      (response) => response.data
    );
  }
  static getFollowing(id, options) {
    const queries = id ? "?user_id=" + id : "";
    return HTTP.get("social-network/get-following" + queries, options).then(
      (response) => response.data
    );
  }
  static getMutualFavouriteUsers(options) {
    return HTTP.get("social-network/get-mutual-favourite-users", options).then(
      (response) => response.data
    );
  }
  static getLikedMeUsers(options) {
    return HTTP.get("social-network/get-liked-me-users", options).then(
      (response) => response.data
    );
  }
  static getChatsUsers(id, options) {
    const queries = id ? "?user_id=" + id : "";
    return HTTP.get("social-network/get-chat-users" + queries, options).then(
      (response) => response.data
    );
  }
  static searchUsers(options) {
    // console.log("options: ", options);
    return HTTP.get("social-network/search-users", options).then(
      (response) => response.data
    );
  }
  static resetGuests(options) {
    return HTTP.get("social-network/reset-guests", options).then(
      (response) => response.data
    );
  }
  static sendErrorsReport(data, options) {
    return HTTP.post("auth/report-client-error", data, options).then(
      (response) => response.data
    );
  }
  static voteForPost(data, options) {
    return HTTP.post("posts/vote-for-post", data, options).then(
      (response) => response.data
    );
  }
  static removeAvatarSocial(data, options) {
    return HTTP.post("files/remove-image", data, options).then(
      (response) => response.data
    );
  }
  static sortAvatarSocial(data, options) {
    return HTTP.post("files/sort-images", data, options).then(
      (response) => response.data
    );
  }
  static sortImages(data, options) {
    return HTTP.post("files/sort-images", data, options).then(
      (response) => response.data
    );
  }
  static uploadSocialProfileImages(data, options) {
    return HTTP.post("auth/upload-social-profile-images", data, options).then(
      (response) => response.data
    );
  }
  static updateSocialProfile(data, options) {
    return HTTP.post("auth/update-social-profile", data, options).then(
      (response) => response.data
    );
  }
  // inject('removeImage', endPoint('/files/remove-image'))
  // inject('sortImages', endPoint('/files/sort-images'))
  static useProduct(data, options) {
    return HTTP.post("user-products/use-product", data, options).then(
      (response) => response.data
    );
  }
  static useReport(data, options) {
    return HTTP.post("reports/use-report", data, options).then(
      (response) => response.data
    );
  }
  static getJustclickSubscription(options) {
    return HTTP.get("auth/get-justclick-subscription", options).then(
      (response) => response.data
    );
  }
}
